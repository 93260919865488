import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CoreModule } from '@core/core.module';
import { StringUtils } from '@ingroupe/common-utils';
import { ConfigurationService } from '@services/configuration/configuration.service';

@Injectable({
  providedIn: CoreModule
})
export class UploadPdfService {

  constructor(private http: HttpClient, private config: ConfigurationService) {
  }

    public uploadPdf(file: File, fileName: string, signatories: boolean = false): Promise<string> {
      let path = this.config.getEndPoint('api').post.uploadPdf;
      path = StringUtils.format(path, `${signatories}`);
      const uploadData = new FormData();
      uploadData.append('file', file, fileName);
      return this.http.post<string>(path, uploadData).toPromise();
    }

  public documentUnsigned(id: string): Promise<ArrayBuffer> {
    const path = this.config.getEndPoint('api').get.downloadDocument;
    const newPath = StringUtils.format(path, id, 'false');
    return this.http.get(newPath, { responseType: 'arraybuffer' }).toPromise();
  }

  public documentUnsignedSignatory(id: string): Promise<ArrayBuffer> {
    const path = this.config.getEndPoint('api').get.downloadDocumentSignatory;
    const newPath = StringUtils.format(path, id, 'false');
    return this.http.get(newPath, { responseType: 'arraybuffer' }).toPromise();
  }

  public renamePdf(documentId: string, name: string, signatories: boolean): Promise<any> {
    const path = this.config.getEndPoint('api').put.renamePdf;
    const newPath = StringUtils.format(path, documentId, `${signatories}`);
    return this.http.put(newPath, { filename: name }).toPromise();
  }
}
