import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StringUtils } from '@ingroupe/common-utils';
import { ConfigurationService } from '@services/configuration/configuration.service';
import { DocumentInfoService } from '@services/document/document-info.service';
import { UploadPdfService } from '@services/document/upload-pdf.service';
import { ToastNotificationService } from '@services/toast-notification/toast-notification.service';
import { I18nDomainService } from '@services/i18n/i18n-domain.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SignalDocumentModalComponent } from './signal-document-modal/signal-document-modal.component';
import { DocumentConverterUtils } from '@shared/utils/document-converter.utils';
import { SignatureService } from '@services/document/signature.service';
import { SignatureError } from '@models/signature/signature-error.enum';
import { AuthMode } from '@models/user/mode.enum';
import { UserService } from '@services/user/user.service';
import { ModalConfirmComponent } from '@components/modal-confirm/modal-confirm.component';
import { Subscription } from 'rxjs';
import {IngroupeTranslateService} from '@ingroupe/translate';

@Component({
  selector: 'app-preview-document',
  templateUrl: './preview-document.component.html',
  styleUrls: ['./preview-document.component.scss']
})
export class PreviewDocumentComponent implements OnInit {

  private static readonly ERRORS_MAP: Map<SignatureError, string> = new Map<SignatureError, string>();

  /**
   * the name of the file
   */
  documentName: string;

  /**
   * The id of the document
   */
  documentId: string;

  /**
   * The name of the person who produced the document
   */
  creatorName: string;

  /**
   * the string file to pass in pdf viewer component
   */
  base64Document: string;

  /**
   * The route data subsciption
   */
  private dataSubscription: Subscription;

  public constructor(private activatedRoute: ActivatedRoute, private documentInfoService: DocumentInfoService,
                     private translateService: I18nDomainService, private uploadPdfService: UploadPdfService,
                     private router: Router, private toastNotificationService: ToastNotificationService,
                     private configService: ConfigurationService, private modalService: NgbModal,
                     private signatureService: SignatureService, private userService: UserService,
                     private route: ActivatedRoute, private ingroupeIntranslateService: IngroupeTranslateService) {
    PreviewDocumentComponent.ERRORS_MAP.set(SignatureError.CARD_SIGNATURE_FAILED, 'signature.error.card-signature-failed');
    PreviewDocumentComponent.ERRORS_MAP.set(SignatureError.CARD_INVALID_CERTIFICATE, 'signature.error.card-invalid-certificate');
    PreviewDocumentComponent.ERRORS_MAP.set(SignatureError.CARD_REVOKED_CERTIFICATE, 'signature.error.card-revoked-certificate');
  }

  /**
   * On init component listener
   */
  public ngOnInit(): void {
    this.documentId = this.activatedRoute.snapshot.paramMap.get('id');
    this.getDocumentInfo(this.documentId);

    this.dataSubscription = this.route.data.subscribe(data => {
      this.base64Document = DocumentConverterUtils.fromByteToBase64(data.base64Document);
    });
  }

  /**
   * ge info document
   *
   * @param id document id
   */
  public getDocumentInfo(id: string): void {
    this.documentInfoService.publicDocumentInfo(id)
      .then(documentInfo => {
        this.documentName = documentInfo.filename;
        this.creatorName = documentInfo.commonName;
      })
      .catch(() => this.router.navigate(['']));
  }

  /**
   * sign document, go to qualified signature page if card authentication otherwise
   */
  public signDocument(): void {
    if (this.userService.getAuthMode() === AuthMode.MOBILE) {
      this.doAdvancedSignature();
    } else {
      this.goToQualifiedSignaturePage();
    }
  }

  /**
   * got to back step
   */
  public goBack(): void {
    this.router.navigate(['guest/home', this.documentId]);
  }

  /**
   * open modal
   */
  public openSignalModal(): void {
    this.modalService.open(SignalDocumentModalComponent, { centered: true, size: 'md' });
  }

  /**
   * build document url
   */
  public buildDocumentUrl(): string {
    return StringUtils.format(this.configService.getEndPoint('api.get.downloadDocumentSignatory'), this.documentId, 'false');
  }

  /**
   * go to qualified signature page
   */
  private goToQualifiedSignaturePage(): void {
    this.signatureService.checkBeforeSignature(this.documentId).then(() => {
      this.router.navigate(['signature/guest', this.documentId], { queryParams: { multiSignatories: 'true' } });
    }).catch((error) => {
      this.handleSignatureError(error);
    });

  }

  /**
   * sign a document in advanced level
   */
  private doAdvancedSignature(): void {
    const lang = this.ingroupeIntranslateService.getCurrentLang();
    this.signatureService.doAdvancedSignature(this.documentId, lang).then(() => {
      this.router.navigate(['signature/confirmation', this.documentId], { queryParams: { multiSignatories: true } });
    }).catch((error) => {
      this.handleSignatureError(error);
    });
  }

  /**
   * handle signature error
   *
   * @param error error
   *
   */
  private handleSignatureError(error): void {
    if (error === SignatureError.CANNOT_BE_SIGNED) {
      this.openLimitSignatoriesDialog();
    } else {
      this.toastNotificationService.error('signature.error.unable-to-sign-document');
    }
  }

  /**
   * open limit Signatories dialog
   */
  private openLimitSignatoriesDialog(): void {
    const modalRef = this.modalService.open(ModalConfirmComponent, { centered: true, size: 'md' });
    const newMessageLine1 = this.translateService.resolveMessage('guest.preview-document.modal.number-of-signatories-exceeded.line-1');
    const newMessageLine2 = this.translateService.resolveMessage('guest.preview-document.modal.number-of-signatories-exceeded.line-2');

    // eslint-disable-next-line
    modalRef.componentInstance.title = this.translateService.resolveMessage('guest.preview-document.modal.number-of-signatories-exceeded.title');
    modalRef.componentInstance.message = `${newMessageLine1}<br />${newMessageLine2}`;
  }
}
