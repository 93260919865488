import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutComponent } from '@pages/about/about.component';
import { FaqComponent } from '@pages/faq/faq.component';
import { HomeComponent } from '@pages/home/home.component';
import { MyDocumentsComponent } from '@pages/my-documents/my-documents.component';
import { DashboardComponent } from '@pages/signature/dashboard/dashboard.component';
import { AuthGuard } from '@guards/auth.guard';
import { DashboardResolver } from '@resolvers/dashboard.resolver';
import { NotificationsComponent } from '@pages/notifications/notifications.component';
import { NotificationActiveResolver } from '@resolvers/notification-active.resolver';
import { BrowserGuard } from '@guards/browser.guard';

const routes: Routes = [
  { path: 'home', component: HomeComponent, canActivate: [BrowserGuard] },
  { path: 'about', component: AboutComponent, canActivate: [BrowserGuard] },
  { path: 'faq', component: FaqComponent, canActivate: [BrowserGuard] },
  { path: 'my-documents', component: MyDocumentsComponent, canActivate: [AuthGuard, BrowserGuard] },
  { path: 'notifications', component: NotificationsComponent,
    canActivate: [AuthGuard, BrowserGuard],
    resolve: { notification: NotificationActiveResolver }},
  {
    path: 'signature',
    canActivate: [AuthGuard, BrowserGuard],
    children: [
      { path: 'dashboard', component: DashboardComponent,
        resolve: { countByStatuses: DashboardResolver, notification: NotificationActiveResolver } }
    ]
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MenuRoutingModule {
}
