<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li><a routerLink="signature/dashboard"><i class="fas fa-home mr-1"></i></a></li>
    <li class="breadcrumb-item">
      <a routerLink="signature/dashboard">{{ (isAuthenticated ? 'breadcrumb.dashboard' : 'breadcrumb.login') | themeTranslate | capitalizeFirst }}</a>
    </li>
    <li class="breadcrumb-item active" aria-current="page">{{'breadcrumb.about' | themeTranslate | capitalizeFirst}}</li>
  </ol>
</nav>

<div class="d-flex flex-column h-100">
  <h1 themeTranslate>about.title</h1>
  <p innerHTML="{{'about.content' | themeTranslate}}"></p>
</div>
