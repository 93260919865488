import { Directive, HostListener } from '@angular/core';

@Directive({
    selector: '[lockCopyPaste]'
})
export class LockCopyPasteDirective {
    /**
     * Constructor
     */
    public constructor() {

    }

    /**
     * Lock paste action
     *
     * @param e : the keyboard event
     */
    @HostListener('paste', ['$event'])
    public blockPaste(e: KeyboardEvent): void {
        e.preventDefault();
    }

    /**
     * Lock copy action
     *
     * @param e : the keyboard event
     */
    @HostListener('copy', ['$event'])
    public blockCopy(e: KeyboardEvent): void {
        e.preventDefault();
    }

    /**
     * Lock cut action
     *
     * @param e : the keyboard event
     */
    @HostListener('cut', ['$event'])
    public blockCut(e: KeyboardEvent): void {
        e.preventDefault();
    }
}
