<h3 class="my-3">{{'upload-pdf.rename.sub-title.my-document' | themeTranslate | capitalizeFirst}}</h3>

<form #uploadForm="ngForm" (ngSubmit)="goToPreviewPdf(uploadForm)">
  <span class="bold mr-3 my-3" *ngIf="file">{{file.name}}</span>
  <span class="bold mr-3 my-3" *ngIf="infosDocument">{{infosDocument.filename}}</span>
  <div class="form-group my-3">
    <label class="mb-0 bold" for="fileName">{{'upload-pdf.rename.input-label' | themeTranslate | capitalizeFirst}}
      *</label>
    <input id="fileName" name="fileName" type="text" maxlength="50" (blur)="formatFileName()"
           placeholder="{{'upload-pdf.rename.input-placeholder' | themeTranslate }}"
           required class="form-control" [(ngModel)]="newNamePdf" autocomplete="off">
    <div class="text-info invalid">
      <div
        *ngIf="submitted && !pdfNameNotEmpty">{{'upload-pdf.rename.invalid-form' | themeTranslate | capitalizeFirst}}</div>
      <div
        *ngIf="submitted && !pdfNameAtLeastOneChar">{{'upload-pdf.rename.invalid-name' | themeTranslate | capitalizeFirst}}</div>
      <div
        *ngIf="submitted && !pdfNameLengthValidator">{{'upload-pdf.rename.too-long-name' | themeTranslate | capitalizeFirst}}</div>
    </div>
  </div>

  <div class="row">
    <div class="form-group col-12 col-sm-6">
      <h3 class="my-3 mt-md-5">{{'upload-pdf.rename.sub-title.signatories' | themeTranslate | capitalizeFirst}}</h3>
      <div class="form-check pl-0">
        <label class="label">{{'upload-pdf.rename.signatories.radio-one' | themeTranslate |capitalizeFirst}}
          <input type="radio" name="radio-one" id="radio-one" value="false" [(ngModel)]="signatories">
          <span class="checkmark-radio"></span>
        </label>
      </div>
      <div class="form-check pl-0">
        <label class="label">{{'upload-pdf.rename.signatories.radio-many' | themeTranslate |capitalizeFirst}}
          <input type="radio" name="radio-many" id="radio-many" value="true" #multiSignatories
                 [(ngModel)]="signatories">
          <span class="checkmark-radio"></span>
        </label>
      </div>
      <div class="text-info invalid">
        <span
          *ngIf="submitted && signatories === null">{{'upload-pdf.rename.invalid-form' | themeTranslate | capitalizeFirst}}</span>
      </div>
    </div>

    <div class="align-self-end col-12 offset-sm-1 offset-md-0 col-sm-5 px-3" *ngIf="multiSignatories.checked">
      <div class="alert alert-info p-3 row no-gutters">
        <i class="fas fa-exclamation-circle col-1"></i>
        <div class="col-11 pl-3">
          <span innerHTML="{{'share-document.step1.alert' | themeTranslate}}"></span>
        </div>
      </div>
    </div>
  </div>

  <button class="btn btn-outline-primary" type="button" (click)="goBack()">
    {{'upload-pdf.rename.btn-return' | themeTranslate |capitalizeFirst}}</button>
  <button class="btn btn-primary ml-3" type="submit">
    {{'upload-pdf.rename.btn-continue' | themeTranslate |capitalizeFirst}}</button>
</form>



