<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li>
      <a routerLink="signature/dashboard"><i class="fas fa-home mr-1"></i></a>
    </li>
    <li class="breadcrumb-item">
      <a routerLink="signature/dashboard">{{ (isAuthenticated ? 'breadcrumb.dashboard' : 'breadcrumb.login') | themeTranslate | capitalizeFirst }}</a></li>
    <li class="breadcrumb-item active" aria-current="page">{{'breadcrumb.legal-notice' | themeTranslate | capitalizeFirst}}</li>
  </ol>
</nav>

<h2 class="mb-3 demi">{{'legal-notice.title' | themeTranslate | capitalizeFirst}}</h2>

<div class="legal-notice">
  <p innerHTML="{{'legal-notice.intro' | themeTranslate}}"></p>
  <p><u>{{'legal-notice.publication-director' | themeTranslate}}</u> : Julien DEJANOVIC</p>
  <p><u>{{'legal-notice.contact' | themeTranslate}}</u></p>
  <ul>
    <li>{{'legal-notice.phone' | themeTranslate}} : +377 98 98 96 97</li>
    <li>{{'legal-notice.email' | themeTranslate}} : <a href="mailto:identitenumerique@gouv.mc">identitenumerique@gouv.mc</a></li>
  </ul>
  <p>{{'legal-notice.hosting' | themeTranslate}}</p>
  <p><u>{{'legal-notice.contact' | themeTranslate}}</u></p>
  <ul>
    <li>{{'legal-notice.phone' | themeTranslate}} : +377 97 70 20 00</li>
  </ul>
  <p innerHTML="{{'legal-notice.hosting-informations' | themeTranslate}}"></p>
  <p><u>{{'legal-notice.contact' | themeTranslate}}</u></p>
  <ul>
    <li>{{'legal-notice.phone' | themeTranslate}} : +377 97 70 20 00</li>
  </ul>
</div>



