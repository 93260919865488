import { Injectable } from '@angular/core';
import { CoreModule } from '../core.module';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { DocumentDto } from '@models/document/document.model';
import { DocumentService } from '@services/document/document.service';

@Injectable({ providedIn: CoreModule })
export class UploadResolver implements Resolve<DocumentDto> {
  constructor(
    private documentService: DocumentService,
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<DocumentDto> {
    const documentId: string = route.paramMap.get('id');
    return this.documentService.infosUploadDocument(documentId).catch((reason: any) => {
      throw new Error('Unable to get document metadata...');
    });
  }
}
