<h2 class="mb-3">{{'confirmation.title' | themeTranslate | capitalizeFirst}}</h2>
<p class="text-justify">
  <ng-container *ngIf="isMulti">
    <span innerHtml="{{'confirmation.content.multi' | themeTranslate | capitalizeFirst}}"></span>
  </ng-container>
  <ng-container *ngIf="!isMulti">
    <span>{{'confirmation.content.can' | themeTranslate | capitalizeFirst}}</span>
    <span class="bold">{{'confirmation.content.download' | themeTranslate}}</span>
    <span>{{'confirmation.content.sign' | themeTranslate}}</span>
    <span class="bold">{{'confirmation.content.transmit' | themeTranslate}}</span>
  </ng-container>
</p>

<div class="d-flex flex-column flex-sm-row my-3 my-sm-4">
  <button class="btn btn-primary" fileSaver [method]="'GET'" [url]="buildDocumentUrl()"
          *ngIf="!isMulti">{{'confirmation.buttons.download' | themeTranslate}}</button>
  <a routerLink="/signature/dashboard" id="home-link" [ngClass]="{'ml-0 ml-sm-3 mt-3 mt-sm-0': !isMulti}"
     class="btn btn-link btn-outline-primary">{{'confirmation.buttons.return' | themeTranslate}}</a>
</div>
