<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li><a routerLink="signature/dashboard"><i class="fas fa-home mr-1"></i></a></li>
    <li class="breadcrumb-item">
      <a routerLink="signature/dashboard">{{ 'breadcrumb.dashboard' | themeTranslate | capitalizeFirst }}</a>
    </li>
    <li class="breadcrumb-item active"
        aria-current="page">{{'breadcrumb.documents-list' | themeTranslate | capitalizeFirst}}</li>
  </ol>
</nav>

<h2>{{'my-documents.title' | themeTranslate | capitalizeFirst}}</h2>

<div class="row">
  <div class="col-12 col-md-6">
    <div class="form-group mt-4">
      <label>{{'my-documents.search.input' | themeTranslate | capitalizeFirst}}</label>
      <input #filename class="form-control" type="text" [ngbTypeahead]="search"
             placeholder="{{'my-documents.search.placeholder' | themeTranslate}}"/>
      <i class="fas fa-search"></i>
    </div>
  </div>
</div>


<button class="btn btn-filter mb-3 demi"
        (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="isCollapsed"
        aria-controls="filterCollapse">{{'my-documents.filters.btn-filter' | themeTranslate }}
  <i *ngIf="!isCollapsed" class="fas fa-chevron-down ml-3"></i>
  <i *ngIf="isCollapsed" class="fas fa-chevron-up ml-3"></i>
</button>

<div *ngIf="isMobile" class="col-12 d-flex flex-wrap my-2 px-0">
  <ng-container *ngFor="let tagCat of pagination.filters | keyvalue">
    <ng-container *ngIf="tagCat.value.constructor.name === 'Array'">
      <ng-container *ngFor="let tagValue of tagCat.value">
        <ng-container [ngTemplateOutlet]="tag" [ngTemplateOutletContext]="{tagKey: tagCat.key, tagValue: tagValue}">
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="tagCat.value.constructor.name !== 'Array'">
      <ng-container [ngTemplateOutlet]="tag" [ngTemplateOutletContext]="{tagKey: tagCat.key, tagValue: tagCat.value}">
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngFor="let tagSortCat of pagination.sorts | keyvalue">
    <ng-container [ngTemplateOutlet]="tag"
                  [ngTemplateOutletContext]="{tagKey: tagSortCat.key, tagValue: tagSortCat.value.direction, sort: true}">
    </ng-container>
  </ng-container>

</div>

<form #filterForm="ngForm">
  <div id="filterCollapse" [ngbCollapse]="!isCollapsed">
    <div class="border rounded my-3">
      <div class="card-body">
        <div class="d-flex justify-content-end">
          <a class="nav-link" (click)="initFilters()">
            {{'my-documents.filters.init-filters' | themeTranslate | capitalizeFirst}}</a>
        </div>
        <div class="row">
          <div class="col-12 col-md-8">
            <h2 class="mb-3 underline">{{'my-documents.filters.filter-by' | themeTranslate | capitalizeFirst}}
            </h2>
            <div class="row">
              <div class="col-12 col-md-8">
                <div class="row">
                  <div class="col-6 pl-md-5">
                    <h3>{{'my-documents.filters.transmitter' | themeTranslate | capitalizeFirst}}</h3>
                    <div class="form-check pl-0">
                      <label class="label"
                             for="creatorCode-me">{{'my-documents.filters.me-you' | themeTranslate | capitalizeFirst}}
                        <input type="radio" name="creatorCode" value="1" id="creatorCode-me" #creatorCodeMe
                               (change)="filter($event.currentTarget)">
                        <span class="checkmark-radio"></span>
                      </label>
                    </div>
                    <div class="form-check pl-0">
                      <label class="label"
                             for="creatorCode-other">{{'my-documents.filters.other' | themeTranslate | capitalizeFirst}}
                        <input type="radio" name="creatorCode" value="2" id="creatorCode-other" #creatorCodeOther
                               (change)="filter($event.currentTarget)">
                        <span class="checkmark-radio"></span>
                      </label>
                    </div>
                  </div>
                  <div class="col-6">
                    <h3>{{'my-documents.filters.availability' | themeTranslate | capitalizeFirst}}</h3>
                    <div class="custom-control custom-checkbox">
                      <input class="custom-control-input" type="checkbox" name="status" [value]="STATUS_SOON_EXPIRED"
                             (change)="filter($event.currentTarget)" [id]="'filter-' + STATUS_SOON_EXPIRED">
                      <label class="custom-control-label" [for]="'filter-' + STATUS_SOON_EXPIRED">
                        {{'my-documents.filters.imminent-deletion' | themeTranslate | capitalizeFirst}}
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12 col-md-4 mt-3 mt-md-0">
                <h3>{{'my-documents.filters.status.label' | themeTranslate | capitalizeFirst}}</h3>
                <ng-container *ngFor="let status of STATUS_LIST">
                  <div class="custom-control custom-checkbox">
                    <input class="custom-control-input" type="checkbox" name="status" [value]="status"
                           (change)="filter($event.currentTarget)" [id]="'filter-' + status">
                    <label class="custom-control-label" [for]="'filter-' + status">
                      {{'my-documents.filters.status.' + status | themeTranslate | capitalizeFirst}}
                    </label>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-4 mt-3 mt-md-0 pl-md-5 separator">
            <h2 class="mb-md-3">{{'my-documents.filters.sort-by' | themeTranslate | capitalizeFirst}}</h2>
            <div class="row pl-md-4">
              <div class="col-7 col-md-12 d-flex flex-column">
                <h3
                  class="mt-2 mt-md-0">{{'my-documents.filters.alphabetical-order' | themeTranslate | capitalizeFirst}}</h3>
                <div class="form-check form-check-inline">
                  <label class="label" for="filename-asc">A <i class="fas fa-long-arrow-alt-right"></i> Z
                    <input type="radio" name="filename" id="filename-asc" [(ngModel)]="filterSort.filename"
                           [value]="sortDirection.ASC"
                           (change)="sortCriteriaChange($event.target.name, filterSort.filename)">
                    <span class="checkmark-radio"></span>
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <label class="label" for="filename-desc">Z <i class="fas fa-long-arrow-alt-right"></i> A
                    <input class="form-check-input" type="radio" name="filename" id="filename-desc"
                           [value]="sortDirection.DESC" [(ngModel)]="filterSort.filename"
                           (change)="sortCriteriaChange($event.target.name, filterSort.filename)">
                    <span class="checkmark-radio"></span>
                  </label>
                </div>
              </div>

              <div class="col-5 col-md-12 mt-0 mt-md-3">
                <h3 class="mt-2 mt-md-0">{{'my-documents.filters.date' | themeTranslate | capitalizeFirst}}</h3>
                <div class="form-check form-check-inline">
                  <label class="label" for="modifiedDate-desc">
                    {{'my-documents.filters.most-recent' | themeTranslate | capitalizeFirst}}
                    <input class="form-check-input" type="radio" name="modifiedDate" id="modifiedDate-desc"
                           [value]="sortDirection.DESC" [(ngModel)]="filterSort.modifiedDate" #modifiedDateDesc
                           (change)="sortCriteriaChange($event.target.name, filterSort.modifiedDate)">
                    <span class="checkmark-radio"></span>
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <label class="label" for="modifiedDate-asc">
                    {{'my-documents.filters.least-recent' | themeTranslate | capitalizeFirst}}
                    <input class="form-check-input" type="radio" name="modifiedDate" id="modifiedDate-asc"
                           [value]="sortDirection.ASC" [(ngModel)]="filterSort.modifiedDate"
                           (change)="sortCriteriaChange($event.target.name, filterSort.modifiedDate)">
                    <span class="checkmark-radio"></span>
                  </label>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</form>

<h3 class="text-center my-5"
    *ngIf="documents && !documents.content.length">{{'my-documents.no-document' | themeTranslate | capitalizeFirst}}</h3>

<div *ngIf="documents && documents.content" class="mt-3 mt-md-5 d-flex flex-wrap">
  <div class="card-document p-2 mr-0 mr-md-3 mb-3" *ngFor="let document of documents.content"
       (click)="navigateToDetail(document.id)">
    <div class="d-flex flex-row justify-content-between align-items-baseline">
      <div class="mr-3 bold document-name" [title]="document.filename">{{document.filename}}</div>
      <div class="badge"
           [class]="formatBadge(document.status)">{{'my-documents.status.' + document.status | themeTranslate | capitalizeFirst}}</div>
    </div>

    <div class="my-2">{{'my-documents.create-by' | themeTranslate | capitalizeFirst}} {{document.commonName}}
      {{'my-documents.the' | themeTranslate}} {{document.createdDate | date:'dd/MM/yyyy'}}</div>
    <div>{{'my-documents.available-until' | themeTranslate | capitalizeFirst}}
      {{document.expiredDate | date:'dd/MM/yyyy'}}</div>
    <div>{{'my-documents.modified-date' | themeTranslate | capitalizeFirst}}
      {{document.modifiedDate | date:'dd/MM/yyyy'}}
      {{'my-documents.modified-date-at' | themeTranslate}}
      {{document.modifiedDate | date:'HH:mm:ss'}}</div>
  </div>
</div>

<div class="pagination my-0" *ngIf="documents && documents.totalPages > 1">
  <ngb-pagination [collectionSize]="documents.totalElements" [pageSize]="documents.pageable.pageSize"
                  [(page)]="documents.pageable.pageNumber" [maxSize]="5" [rotate]="true" size="sm"
                  [ellipses]="false"
                  (pageChange)="getDocumentsList($event)">
    <ng-template ngbPaginationPrevious><i class="mroad-ico-chevron_gauche"></i></ng-template>
    <ng-template ngbPaginationNext><i class="mroad-ico-chevron_droite"></i></ng-template>
  </ngb-pagination>
</div>

<ng-template #tag let-tagKey="tagKey" let-tagValue="tagValue" let-isSort="sort">
  <div class="tag px-2 mr-2 mb-2" (click)="untag(tagKey, tagValue, isSort)" *ngIf="tagKey !== 'partialFilename'">
    {{'my-documents.filters.tag.' + tagKey + '.' + tagValue | themeTranslate}} <i class="fas fa-times ml-1"></i>
  </div>
</ng-template>

