import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CoreModule } from '@core/core.module';
import { ObjectUtils, StringUtils } from '@ingroupe/common-utils';
import { DocumentDto } from '@models/document/document.model';
import { ShareDocument } from '@models/document/share-document.model';
import { ConfigurationService } from '@services/configuration/configuration.service';
import { Pageable } from '@models/pageable/pageable.model';
import { DocumentList } from '@models/document/document-list';

@Injectable({
  providedIn: CoreModule
})
export class DocumentService {
  constructor(private http: HttpClient, private config: ConfigurationService) {
  }

  /**
   * Get document metadata
   *
   * @param uuid : the document uuid to search
   * @return promise of Document
   */
  getDocumentMetadata(uuid: string): Promise<DocumentDto> {
    const url: string = this.config.getEndPoint('api.get.shareDocumentInfo');
    return this.http.get<DocumentDto>(StringUtils.format(url, uuid)).toPromise();
  }

  /**
   * Update the expected count signatures
   *
   * @param documentId : the related document uuid
   * @param expectedCount : the expected signature count
   */
  updateExpectedSignatureCount(documentId: string, expectedCount: number): Promise<any> {
    const url: string = this.config.getEndPoint('api.put.documentExpectedSignatureCount');
    return this.http.put(StringUtils.format(url, documentId), { expectedSignatureCount: expectedCount }).toPromise();
  }

  /**
   * Share a document
   *
   * @param documentId : thye document uuid to share
   * @param expectedSignatureCount : the expected signature count
   */
  share(documentId: string, expectedSignatureCount: number): Promise<any> {
    const share: ShareDocument = {
      document: {
        expectedSignatureCount
      }
    };

    const url: string = this.config.getEndPoint('api.put.shareDocument');
    return this.http.put(StringUtils.format(url, documentId), share).toPromise();
  }

  /**
   * Get the document list
   *
   * @param pageable: Pagination & Filters parameters
   */
  public getDocumentsList(pageable: Pageable = null): Promise<DocumentList> {
    let params: HttpParams = new HttpParams();
    if (ObjectUtils.isNotNullOrUndefined(pageable)) {
      params = pageable.toUrlParams();
    }

    const path = this.config.getEndPoint('api.get').documentList;
    return this.http.get<DocumentList>(path, { params }).toPromise();
  }

  /**
   * Close the document
   */
  public closeDocument(documentId: string): Promise<any> {
    const url: string = this.config.getEndPoint('api.put.closeDocument');
    return this.http.put(StringUtils.format(url, documentId), {}).toPromise();
  }

  /**
   * Cancel a document
   *
   * @param documentId id of the document
   */
  public cancelDocument(documentId: string): Promise<any> {
    const url: string = this.config.getEndPoint('api.put.cancelDocument');
    return this.http.put(StringUtils.format(url, documentId), {}).toPromise();
  }

  public infosUploadDocument(documentId: string): Promise<DocumentDto> {
    const path = this.config.getEndPoint('api').get.infosUploadDocument;
    const newPath = StringUtils.format(path, documentId);
    return this.http.get(newPath).toPromise();
  }

  public unlockDocument(documentUuid: string): any {
    const url: string = StringUtils.format(
      this.config.getEndPoint('api.patch.unlockDocument'), documentUuid);
    return this.http.patch(url, {}).toPromise();
  }
}
