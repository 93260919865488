import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ArrayUtils, StringUtils } from '@ingroupe/common-utils';
import { StatusFilter } from '@models/document/status-filter.enum';
import { SessionStorageService } from '@services/storage/session-storage.service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';

interface AlertType { name: 'warning' | 'info'; severity: number }
interface ActivityItem {
  count: number; alertType: AlertType; icon: string;
  linkFirst: boolean; pluralMessageType: 'plural' | 'singular'; status: StatusFilter;
}

@AutoUnsubscribe()
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  /**
   * notification message display only if notification not activated already
   */
  showNotificationBanner: boolean;
  countByStatuses: ActivityItem[];
  private dataSubscription: Subscription;
  private iconByStatusesMap: Map<StatusFilter, string>;
  private warnAlert: AlertType = { name: 'warning', severity: 1 };
  private infoAlert: AlertType = { name: 'info', severity: 0 };

  constructor(private router: Router, private route: ActivatedRoute, private sessionStorageService: SessionStorageService) {
    this.iconByStatusesMap = new Map<StatusFilter, string>();
    this.iconByStatusesMap.set(StatusFilter.SOON_EXPIRED, 'fas fa-exclamation-circle');
    this.iconByStatusesMap.set(StatusFilter.ALL_SIGNED, 'fas fa-info-circle');
    this.iconByStatusesMap.set(StatusFilter.TO_SIGNED, 'fas fa-info-circle');
  }

  ngOnInit(): void {
    this.sessionStorageService.remove('idDocument');
    this.dataSubscription = this.route.data.subscribe(data => {
      const countByStatuses: Map<StatusFilter, number> = data.countByStatuses;
      this.showNotificationBanner = StringUtils.isBlank(data.notification.email);
      this.countByStatuses = [];
      countByStatuses.forEach((count: number, status: StatusFilter) => {
        if (count <= 0) {
          ArrayUtils.remove(this.countByStatuses, { status }, 'status');
        } else {
          this.countByStatuses.push({
            count,
            alertType: ArrayUtils.contains([StatusFilter.SOON_EXPIRED], status) ? this.warnAlert : this.infoAlert,
            icon: this.iconByStatusesMap.get(status),
            linkFirst: ArrayUtils.contains([StatusFilter.SOON_EXPIRED], status),
            pluralMessageType: count > 1 ? 'plural' : 'singular',
            status
          });
        }
      });
    });
  }

  ngOnDestroy(): void {
    // DON'T REMOVE THIS METHOD ! IS USED BY AutoUnsubscribe decorator !
  }

  public signDocument(): void {
    this.router.navigate(['signature/upload-pdf']);
  }

  public navigateToMyDocuments(): void {
    this.router.navigate(['my-documents']);
  }

}
