<div class="d-flex flex-column">

  <div class="alert alert-custom " *ngIf="isDocumentSigned">
    <span class="cross-checked">{{'access-statement.cross-checked'| themeTranslate  | capitalizeFirst}}</span><span>{{'access-statement.errors.access-code.already-signed' | themeTranslate  | capitalizeFirst}}</span>
  </div>

  <h2>{{'access-statement.title' | themeTranslate | capitalizeFirst}}</h2>
  <div class="description">
    <span class="mr-1">{{'guest.home.description-line-1-part-1' | themeTranslate | capitalizeFirst}}</span>
    <span class="bold mr-1">{{document.commonName}}</span>
    <span class="mr-1">{{'guest.home.description-line-1-part-2' | themeTranslate}}</span>
    <span class="bold">{{document.filename}}.pdf.</span>
  </div>
  <p class="text-justify mt-3 mb-4 pr-3" innerHtml="{{'access-statement.notice' | themeTranslate | capitalizeFirst}}">
  </p>

  <form #accessStatementForm="ngForm" class="d-flex flex-column row no-gutters">
    <div class="form-group col-12 col-md-4 col-lg-3">
      <label for="codeInput">{{'access-statement.code-input.label' | themeTranslate | capitalizeFirst}} *</label>
      <input type="text" id="codeInput" class="form-control" name="codeInput" [(ngModel)]="accessCode"
        #codeInput="ngModel"
        placeholder="{{'access-statement.code-input.placeholder' | themeTranslate  | capitalizeFirst}}"
        (ngModelChange)="isInvalidCode = false" [maxlength]="accessCodeMaxLength" appOnlyNumber appLockCopyPaste
        required [disabled]="tooManyCodeAttempts">
    </div>
    <div class="text-info invalid" *ngIf="submitted">
      <span *ngIf="codeInput.invalid">
        {{'access-statement.errors.access-code.required' | themeTranslate | capitalizeFirst}}</span>
      <span *ngIf="!codeInput.invalid && isInvalidCode && !tooManyCodeAttempts">
        {{'access-statement.errors.access-code.invalid' | themeTranslate | capitalizeFirst}}</span>
      <span *ngIf="!codeInput.invalid && tooManyCodeAttempts">
        {{'access-statement.errors.access-code.too-many-attempts' | themeTranslate | capitalizeFirst}}</span>
    </div>

    <div class="d-flex flex-column flex-sm-row align-items-sm-baseline mt-3">
      <button class="btn btn-primary" [disabled]="codeInput.invalid || tooManyCodeAttempts"
        (click)="verifyAccessCode()">{{'access-statement.button.access' | themeTranslate}}</button>
    </div>
  </form>
</div>
