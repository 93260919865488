<form [formGroup]="shareForm" novalidate>
  <div class="row no-gutters">
    <div class="pl-0 col-12 col-md-7">
      <h2 class="mt-3 mt-md-3">{{'share-document.title' | themeTranslate | capitalizeFirst}}</h2>
      <p class="my-3 no-gutters">{{'share-document.notice' | themeTranslate | capitalizeFirst}}</p>
    </div>
  </div>

  <!--  STEP 1-->
  <div class="d-flex flex-column-reverse flex-md-row my-4">
    <div class="pl-0 col-12 col-md-7 d-flex flex-column">
      <h5 class="demi">{{'share-document.step1.title' | themeTranslate | capitalizeFirst}}</h5>
      <p class="italic">{{'share-document.step1.notice' | themeTranslate | capitalizeFirst}}</p>
      <div class="input-numeric-spinner">
        <div class="form-group">
          <button class="btn btn-primary minus" (click)="setExpectedSignatoriesCount(-1)"></button>
          <input formControlName="expectedSignatureCount" id="expectedSignatureCount" type="text" size="1"
                 maxlength="1" onlyNumber lockCopyPaste/>
          <button class="btn btn-primary plus" (click)="setExpectedSignatoriesCount(1)"></button>
          <button class="btn btn-link ml-3" (click)="updateSignatoriesNumber()"
                  *ngIf="controls.expectedSignatureCount.value !== sharedDocument.expectedSignatureCount">
            {{'share-document.buttons.save-signatories-number' | themeTranslate | capitalizeFirst}}</button>
          <div class="text-info invalid mt-2" *ngIf="!controls.expectedSignatureCount.valid" themeTranslate
               [translateParams]="{min: 1, max: 9}">
            share-document.error.invalid-expected-signatories-count
          </div>
        </div>
      </div>
    </div>
    <div class="p-0 col-12 col-md-5 d-flex align-items-md-center justify-content-md-center">
      <div class="alert alert-info p-3 row no-gutters">
        <i class="fas fa-info-circle col-1"></i>
        <div class="col-11 pl-3">
          <span innerHTML="{{'share-document.step1.alert' | themeTranslate}}"></span>
        </div>
      </div>
    </div>
  </div>

  <!--  STEP 2-->
  <div class="d-flex flex-column flex-md-row my-4">
    <div class="pl-0 col-12 col-md-7 d-flex flex-column">
      <h5 class="demi">{{'share-document.step2.title' | themeTranslate | capitalizeFirst}}</h5>
      <p>{{'share-document.step2.notice' | themeTranslate | capitalizeFirst}}</p>
      <div class="d-flex flex-column align-items-md-baseline">
        <div class="shareUrl bold mr-2 mb-md-3">{{sharedDocument?.accessUrl}}</div>
        <div class="flex-row mt-1 mt-md-0">
          <a class="mr-2" (click)="copyUrl()"> {{'share-document.buttons.copy' | themeTranslate | capitalizeFirst}}</a>
          <a [href]="sharedMail">{{'share-document.step2.mail-link' | themeTranslate | capitalizeFirst}}</a>
        </div>
      </div>
    </div>
    <div class="p-0 col-12 col-md-5 d-flex flex-column justify-content-md-center h-100">
      <div class="col-12 alert alert-info p-3 row no-gutters d-none d-md-flex">
        <i class="fas fa-info-circle col-1"></i>
        <div class="col-11 pl-3">
          <span innerHtml="{{'share-document.step2.alert-info' | themeTranslate}}"></span>
        </div>
      </div>
    </div>
  </div>

  <!--  STEP 3-->
  <div class="d-flex flex-column flex-md-row my-3">
    <div class="pl-0 col-12 col-md-7 d-flex flex-column">
      <h5 class="mb-3 demi">{{'share-document.step3.title' | themeTranslate | capitalizeFirst}}</h5>
      <p>{{'share-document.step3.notice' | themeTranslate | capitalizeFirst}}</p>
      <div class="d-flex flex-row align-items-center mb-3">
        <span class="code" (click)="copyCode()">{{sharedDocument.accessCode}}</span>
        <a class="ml-2" (click)="copyCode()"> {{'share-document.buttons.copy' | themeTranslate | capitalizeFirst}}</a>
      </div>
    </div>
    <div class="p-0 col-12 col-md-5 d-flex flex-column justify-content-md-center h-100">
      <div class="col-12 alert alert-warning-shareDoc p-3 row no-gutters mt-md-2">
        <i class="fas fa-exclamation-circle col-1"></i>
        <div class="col-11 pl-3">
          <span innerHtml="{{'share-document.step3.alert-warn' | themeTranslate}}"></span>
        </div>
      </div>
    </div>
  </div>

  <!--  STEP 4-->
  <div *ngIf="showNotificationBanner" class="d-flex flex-column flex-md-row my-3">
    <div class="pl-0 col-12 col-md-7 d-flex flex-column">
      <h5 class="demi">{{'share-document.step4.title' | themeTranslate | capitalizeFirst}}</h5>
      <span>
        <a class="mr-1"
           routerLink="/notifications"><u>{{'share-document.step4.link' | themeTranslate | capitalizeFirst}}</u></a>
        {{'share-document.step4.notice' | themeTranslate }}
      </span>
    </div>
  </div>

  <div class="d-flex flex-column flex-sm-row align-items-sm-baseline mt-3 mt-md-4">
    <a routerLink="/signature/dashboard" id="home-link" class="btn btn-outline-primary"
       themeTranslate>share-document.buttons.back-to-home</a>
  </div>
</form>
