import { Component, OnInit } from '@angular/core';
import { StringUtils } from '@ingroupe/common-utils';
import { ConfigurationService } from '@services/configuration/configuration.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-sign-confirmation',
  templateUrl: './sign-confirmation.component.html',
  styleUrls: ['./sign-confirmation.component.scss']
})
export class SignConfirmationComponent implements OnInit {
  /**
   * The related document id
   */
  private documentId: string;

  /**
   * Indicate if the document is multi signatories or not
   */
  isMulti: boolean;

  constructor(private route: ActivatedRoute, private configService: ConfigurationService) {
  }

  ngOnInit(): void {
    this.isMulti = StringUtils.equalsIgnoreCase(this.route.snapshot.queryParamMap.get('multiSignatories'), 'true');
    this.documentId = this.route.snapshot.paramMap.get('id');
  }

  public buildDocumentUrl(): string {
    return StringUtils.format(this.configService.getEndPoint('api.get.downloadDocument'), this.documentId, `${!this.isMulti}`);
  }
}
