import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {PublicDocument} from '@models/document/public-document.model';
import {ErrorCodes} from '@models/error/error-codes.enum';
import {DocumentInfoService} from '@services/document/document-info.service';
import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {CoreModule} from '@core/core.module';

@Injectable({ providedIn: CoreModule })
export class DocumentInfoResolver implements Resolve<{ data?: PublicDocument; error?: ErrorCodes} | boolean>{
  constructor(private documentInfoService: DocumentInfoService, private router: Router) {
  }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Promise<{ document?: PublicDocument; error?: ErrorCodes} | boolean> {
    const documentId: string = route.paramMap.get('id');
    try {
      return { document : await this.documentInfoService.publicDocumentInfo(documentId)};
    } catch (e) {
      return this.handleError(route, e);
    }
  }

  handleError(route: ActivatedRouteSnapshot, errorResponse: HttpErrorResponse): Promise< { error: ErrorCodes } | boolean> {
    if (errorResponse.status === 400 && errorResponse.error.code === ErrorCodes.DOCUMENT_COMPLETED) {
      return Promise.resolve({error: ErrorCodes.DOCUMENT_COMPLETED});
    }
    return this.router.navigate(['']);
  }
}
