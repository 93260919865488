import { Injectable } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { I18nDomainService } from '@services/i18n/i18n-domain.service';
import { ConfigurationService } from '@services/configuration/configuration.service';

/**
 * The toast notification service
 */
@Injectable(
  {
    providedIn: SharedModule
  }
)
export class ToastNotificationService {
  /**
   * Constructor
   *
   * @param toastrService : the toastr service instance
   * @param i18nService : the i18n service instance
   */
  public constructor(private configService: ConfigurationService, private toastrService: ToastrService,
                     private i18nService: I18nDomainService) {

  }

  /**
   * show successful toast
   */
  public success(message: string, title?: string, options?: Partial<IndividualConfig>): void {
    options = this.customizeOptions(options);
    this.toastrService.success(this.i18nService.resolveMessage(message) as string,
      this.i18nService.resolveMessage(title) as string, options);
  }

  /**
   * show error toast
   */
  public error(message: string, title?: string, options?: Partial<IndividualConfig>): void {
    options = this.customizeOptions(options);
    this.toastrService.error(this.i18nService.resolveMessage(message) as string,
      this.i18nService.resolveMessage(title) as string, options);
  }

  /**
   * show info toast
   */
  public info(message: string, title?: string, options?: Partial<IndividualConfig>): void {
    options = this.customizeOptions(options);
    this.toastrService.info(this.i18nService.resolveMessage(message) as string,
      this.i18nService.resolveMessage(title) as string, options);
  }

  /**
   * show warning toast
   */
  public warning(message: string, title?: string, options?: Partial<IndividualConfig>): void {
    options = this.customizeOptions(options);
    this.toastrService.warning(this.i18nService.resolveMessage(message) as string,
      this.i18nService.resolveMessage(title) as string, options);
  }

  /**
   * Enabled html into toast message notifcation
   *
   * @param options : options
   */
  private customizeOptions(
    options?: Partial<IndividualConfig>): Partial<IndividualConfig> {
    options = options || {};
    options = Object.assign(options, {
      enableHtml: true,
      timeOut: this.configService.getConfig('toast.timeout'),
    });
    return options;
  }
}
