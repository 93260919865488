<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li><a routerLink="signature/dashboard"><i class="fas fa-home mr-1"></i></a></li>
    <li class="breadcrumb-item">
      <a routerLink="signature/dashboard">{{ 'breadcrumb.dashboard' | themeTranslate | capitalizeFirst }}</a>
    </li>
    <li class="breadcrumb-item active" aria-current="page">{{'breadcrumb.notifications' | themeTranslate | capitalizeFirst}}</li>
  </ol>
</nav>

<form [formGroup]="notificationForm">
  <div class="pl-0 col-12 col-md-7 d-flex flex-column mt-1">
    <h2>{{'notifications.title' | themeTranslate | capitalizeFirst}}</h2>
    <div innerHtml="{{'notifications.notice' | themeTranslate | capitalizeFirst}}"></div>

    <div class="form-group mt-3 d-flex flex-column">
      <label class="mb-3">{{'notifications.switch-title' | themeTranslate | capitalizeFirst}}</label>
      <div class="d-inline-flex toogle-btn">
        <span class="switch-label ml-0">{{'toggle.no' | themeTranslate | capitalizeFirst}}</span>
        <label class="switch">
          <input type="checkbox" (change)="updateNotificationState($event)"
                 [value]="showNotificationSection" [checked]="showNotificationSection">
          <span class="slider round"></span>
        </label>
        <span class="switch-label">{{'toggle.yes' | themeTranslate | capitalizeFirst}}</span>
      </div>
    </div>

    <ng-container *ngIf="showNotificationSection">
      <div class="form-group mt-1 d-flex flex-column">
        <label>{{'notifications.input-mail.label' | themeTranslate | capitalizeFirst}} *</label>
        <ng-container *ngTemplateOutlet="inputMailEditMode ? inputMode : staticMode"></ng-container>

        <ng-template #inputMode>
          <div *ngIf="lastRegisterMail"><span class="bold">Mail actuel: </span> <span>{{lastRegisterMail}}</span></div>
          <input class="form-control" formControlName="notificationMail" type="text"
                 placeholder="{{'notifications.input-mail.placeholder' | themeTranslate}}"/>
        </ng-template>
        <ng-template #staticMode>
          <div class="mb-3">{{lastRegisterMail}} - <a (click)="updateMail()">modifier</a></div>
        </ng-template>

        <div class="text-info invalid mt-2"
             *ngIf="controls.notificationMail.invalid && controls.notificationMail.touched && this.notificationForm.get('notificationMail').value">
          {{'notifications.input-mail.errors.mail-bad-format' | themeTranslate | capitalizeFirst}}
        </div>
        <div class="text-info invalid mt-2" *ngIf="inputMailEditMode && controls.notificationMail.valid && isSameMail()">
          {{'notifications.input-mail.errors.already-saved' | themeTranslate}}
        </div>
      </div>

      <div class="d-flex flex-column flex-sm-row align-items-sm-baseline">
        <a routerLink="/signature/dashboard" id="home-link" class="btn btn-outline-primary">
          {{'notifications.buttons.back-to-home' | themeTranslate}}</a>
        <button *ngIf="inputMailEditMode" class="btn btn-primary ml-0 ml-sm-3 mt-3 mt-sm-0 h-100 align-self-center"
                (click)="activateNotification()"
                [disabled]="notificationForm.invalid && controls.notificationMail.invalid || isSameMail()">
          {{'notifications.buttons.validate' | themeTranslate}}
        </button>
      </div>
    </ng-container>

  </div>
</form>

