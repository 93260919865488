<h2 class="mb-3">{{'guest.preview-document.title' | themeTranslate | capitalizeFirst}}</h2>
<div class="text-justify">
  <span>{{'guest.preview-document.description-line-1-part-1' | themeTranslate | capitalizeFirst}}</span>
  <span class="bold">{{creatorName}}</span>
  <span>{{'guest.preview-document.description-line-1-part-2' | themeTranslate}}</span>
  <span class="bold">{{documentName}}.pdf.</span>
</div>
<div>
  <span class="bold">{{'guest.preview-document.description-line-3-part-1' | themeTranslate | capitalizeFirst}}</span>
</div>

<div class="col p-0 h-auto my-3">
  <ngx-extended-pdf-viewer *ngIf="base64Document" [showUnverifiedSignatures]="false" [useBrowserLocale]="true"
                           [mobileFriendlyZoom]="'100%'"
                           [base64Src]="base64Document" [height]="'60vh'" [showSecondaryToolbarButton]="false"
                           [showBookmarkButton]="false"
                           [showPrintButton]="false" [showDownloadButton]="false" [showOpenFileButton]="false"
                           [contextMenuAllowed]="false" [ignoreKeyboard]="true">
  </ngx-extended-pdf-viewer>
</div>
<div class="d-flex justify-content-end flex-md-row flex-column">
  <a (click)="openSignalModal()"
     class="btn btn-link">{{'guest.preview-document.button.signal' | themeTranslate | capitalizeFirst}}</a>
  <button (click)="goBack()"
          class="btn btn-outline-primary ml-md-3">{{'guest.preview-document.button.cancel' | themeTranslate | capitalizeFirst}}</button>
  <button class="btn btn-outline-primary ml-md-3" fileSaver [method]="'GET'"
          [url]="buildDocumentUrl()">{{'guest.preview-document.button.download' | themeTranslate}}</button>
  <button (click)="signDocument()"
          class="btn btn-primary ml-md-3">{{'guest.preview-document.button.sign' | themeTranslate }}</button>
</div>
