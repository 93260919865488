<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li>
      <a routerLink="signature/dashboard"><i class="fas fa-home mr-1"></i></a>
    </li>
    <li class="breadcrumb-item">
      <a
        routerLink="signature/dashboard">{{ (isAuthenticated ? 'breadcrumb.dashboard' : 'breadcrumb.login') | themeTranslate | capitalizeFirst }}</a>
    </li>
    <li class="breadcrumb-item active"
        aria-current="page">{{'breadcrumb.cookies' | themeTranslate | capitalizeFirst}}</li>
  </ol>
</nav>

<h2 class="mb-3 demi">{{'cookies.title' | themeTranslate | capitalizeFirst}}</h2>

<div class="text-justify">
  <p [innerHTML]="'cookies.intro-1' | themeTranslate"></p>
  <div>
    <p [innerHTML]="'cookies.intro-2.line-1' | themeTranslate"></p>
    <ul>
      <li class="m-1" [innerHTML]="'cookies.intro-2.li-1' | themeTranslate"></li>
      <li class="m-1" [innerHTML]="'cookies.intro-2.li-2' | themeTranslate"></li>
    </ul>
  </div>

  <h3>{{'cookies.technical-cookies.title' | themeTranslate }}</h3>

  <p [innerHTML]="'cookies.technical-cookies.content-1' | themeTranslate"></p>

  <div class="table-responsive mb-3 mb-md-0">
    <table class="table">
      <thead>
      <td>{{'cookies.table-header.cookie-name' | themeTranslate }}</td>
      <td>{{'cookies.table-header.service-name' | themeTranslate }}</td>
      <td>{{'cookies.table-header.finality' | themeTranslate }}</td>
      <td>{{'cookies.table-header.duration' | themeTranslate }}</td>
      </thead>
      <tr>
        <td>mod_auth_openidc_session</td>
        <td>{{'cookies.technical-cookies.table-content.service-name-1' | themeTranslate }}</td>
        <td>{{'cookies.technical-cookies.table-content.finality-1' | themeTranslate }}</td>
        <td>{{'cookies.technical-cookies.table-content.duration-1' | themeTranslate }}</td>
      </tr>
    </table>
  </div>

  <h3>{{'cookies.audience-cookies.title' | themeTranslate }}</h3>

  <p [innerHTML]="'cookies.audience-cookies.content-1' | themeTranslate"></p>

  <p [innerHTML]="'cookies.audience-cookies.content-2' | themeTranslate"></p>

  <p [innerHTML]="'cookies.audience-cookies.content-3' | themeTranslate"></p>

  <div class="table-responsive">
    <table class="table">
      <thead>
      <td>{{'cookies.table-header.cookie-name' | themeTranslate }}</td>
      <td>{{'cookies.table-header.service-name' | themeTranslate }}</td>
      <td>{{'cookies.table-header.finality' | themeTranslate }}</td>
      <td>{{'cookies.table-header.duration' | themeTranslate }}</td>
      </thead>
      <tr>
        <td>pk_id</td>
        <td>{{'cookies.audience-cookies.table-content.service-name-1' | themeTranslate }}</td>
        <td>{{'cookies.audience-cookies.table-content.finality-1' | themeTranslate }}</td>
        <td>{{'cookies.audience-cookies.table-content.duration-1' | themeTranslate }}</td>
      </tr>
      <tr>
        <td>pk_ses</td>
        <td>{{'cookies.audience-cookies.table-content.service-name-2' | themeTranslate }}</td>
        <td>{{'cookies.audience-cookies.table-content.finality-2' | themeTranslate }}</td>
        <td>{{'cookies.audience-cookies.table-content.duration-2' | themeTranslate }}</td>
      </tr>
    </table>
  </div>

  <div>
    <p [innerHTML]="'cookies.audience-cookies.further-information' | themeTranslate"></p>
    <ul>
      <li><a
        target="_blank"
        href="https://www.ccin.mc/fr/fiches-pratiques/l-exploitation-d-un-site-internet-les-bonnes-pratiques-a-respecter">CCIN</a>
      </li>
      <li><a
        target="_blank" href="https://www.cnil.fr/fr/les-conseils-de-la-cnil-pour-maitriser-votre-navigateur">CNIL
      </a></li>
    </ul>
  </div>

  <div class="mb-3">
    <div class="cookie-toggle d-flex ml-auto float-right">
      <h4 class="mb-3 demi"> {{'cookies.audience-cookies.activate' | themeTranslate }} </h4>
      <div class="d-inline-flex toogle-btn ml-4 mt-1">
        <label class="switch">
          <input type="checkbox" (change)="updateCookieState()"
                 [value]="cookieIsActivated" [checked]="cookieIsActivated">
          <span class="slider round"></span>
        </label>
      </div>
    </div>
    <br/>
  </div>

</div>
