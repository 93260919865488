<div class="d-md-none d-flex px-2 py-3" id="burger">
  <button type="button">
    <i class="fas fa-bars fa-2x" (click)="openSidebarMenu()"></i>
  </button>
  <img class="mx-3 img-fluid pb-2" src="assets/images/header/logo/default.svg" (click)="gotoDashboard()" alt="">
  <h3 class="demi" themeTranslate (click)="gotoDashboard()">header.menu.title</h3>
</div>
<div class="d-none d-md-flex flex-column flex-shrink-1">
  <ng-container [ngTemplateOutlet]="menuItems" [ngTemplateOutletContext]="{isSidebar:false}">
  </ng-container>
</div>

<app-sidebar [(display)]="displaySidebarMenu">
  <sidebar-header>
    <a class="btn-close" (click)="closeSidebarMenu()">
      <i class="fas fa-times fa-2x"></i>
    </a>
    <div class="d-flex flex-column">
      <a [routerLink]="displayUserInfo ? 'signature/dashboard' : '/home'" (click)="closeSidebarMenu()">
        <img [src]="logo" class="img-fluid mx-auto align-self-center w-75" alt="">
      </a>
      <ng-container *ngIf="displayUserInfo">
        <h4 class="demi my-3">{{userInfo.given_name}} {{userInfo.family_name}}</h4>
        <a (click)="logout()" themeTranslate>header.menu.logout</a>
      </ng-container>

    </div>

  </sidebar-header>
  <sidebar-body>
    <div class="d-flex flex-column">
      <ng-container [ngTemplateOutlet]="menuItems" [ngTemplateOutletContext]="{isSidebar:true}">
      </ng-container>
    </div>
  </sidebar-body>
</app-sidebar>

<ng-template #menuItems let-isSidebar="isSidebar">
  <div class="px-2 pt-2 m-0 d-flex justify-content-center no-gutters">
    <div class="col-12 col-lg-8 d-flex flex-sm-row justify-content-sm-between">
      <nav class="navbar navbar-expand-md navbar-light p-0 m-0 w-100">
        <div [ngClass]="{'flex-column sidebar-menu-items': isSidebar}" class="w-100 d-flex">
          <ul class="navbar-nav mr-auto">
            <li class="nav-item">
              <a class="nav-link" [routerLink]='[displayUserInfo ? "/signature/dashboard" : "home"]'
                 routerLinkActive="active"
                 (click)="closeSidebarMenu()">{{ (displayUserInfo ? 'header.menu.home' : 'header.menu.login') | themeTranslate }}</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/my-documents" routerLinkActive="active" (click)="closeSidebarMenu()"
                 *ngIf="displayUserInfo"
                 themeTranslate>header.menu.my-documents</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/faq" routerLinkActive="active" (click)="closeSidebarMenu()"
                 themeTranslate>header.menu.faq</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/notifications" routerLinkActive="active" (click)="closeSidebarMenu()"
                 *ngIf="displayUserInfo"
                 themeTranslate>header.menu.notifications</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/about" routerLinkActive="active" (click)="closeSidebarMenu()"
                 themeTranslate>header.menu.about</a>
            </li>
          </ul>
        </div>
        <ingroupe-language-selector (click)="triggerChangeLang($event)" class="align-items-end mt-5 mt-md-0"
                                    [displayType]="(isSidebar) ? languageSelectorDisplayType.INLINE_FULL_TEXT : languageSelectorDisplayType.FULL_TEXT">
        </ingroupe-language-selector>
      </nav>
    </div>
  </div>
</ng-template>
