import { Pipe, PipeTransform } from '@angular/core';
import { StringUtils } from '@ingroupe/common-utils';

@Pipe({
  name: 'capitalizeFirst'
})
export class CapitalizeFirstPipe implements PipeTransform {

  transform(value: string): string {
    if (StringUtils.isNotBlank(value)) {
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
    return;
  }

}
