import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { CoreModule } from '@core/core.module';
import { SharedModule } from '@shared/shared.module';
import { FooterComponent } from '@core/layout/components/footer/footer.component';
import { HeaderComponent } from '@core/layout/components/header/header.component';
import { MenuComponent } from '@core/layout/components/menu/menu.component';
import { ThemeTranslateDirective } from '@core/layout/directives/theme-translate.directive';
import { LayoutComponent } from '@core/layout/layout.component';
import { ThemeTranslatePipe } from '@core/layout/pipes/theme-translate.pipe';
import { LegalNoticeComponent } from '@core/layout/components/footer/components/legal-notice/legal-notice.component';
import { CookiesComponent } from '@core/layout/components/footer/components/cookies/cookies.component';
import { ContactComponent } from '@core/layout/components/footer/components/contact/contact.component';
import { CookieBannerComponent } from '@core/layout/components/cookie-banner/cookie-banner.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    MenuComponent,
    LayoutComponent,
    ThemeTranslatePipe,
    ThemeTranslateDirective,
    LegalNoticeComponent,
    CookiesComponent,
    ContactComponent,
    CookieBannerComponent
  ],
  imports: [
    CoreModule,
    SharedModule,
    BrowserModule,
    RouterModule
  ],
  exports: [
    BrowserModule,
    RouterModule,
    LayoutComponent,
    CoreModule,
    SharedModule,
    ThemeTranslatePipe,
    ThemeTranslateDirective
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class LayoutModule {

}
