<nav aria-label="breadcrumb" *ngIf="gcuDisplayType.gcuReadOnly">
  <ol class="breadcrumb">
    <li><a routerLink="/statement-list"><i class="fas fa-home mr-1"></i></a></li>
    <li class="breadcrumb-item">
      <a routerLink="/signature/dashboard">{{'breadcrumb.dashboard' | themeTranslate}}</a>
    </li>
    <li class="breadcrumb-item active" aria-current="page">{{'breadcrumb.gcu' | themeTranslate }}</li>
  </ol>
</nav>

<div class="d-flex flex-column h-100">
  <h1 themeTranslate>gcu.title</h1>
  <div class="my-4 h-100 flex-grow-1 px-3 text-justify">
    <div [hidden]="hasVerticalScrollbar" class="h-100"
         data-scrollbar>
      <div class="gcu" [innerHtml]="gcuContent"></div>
    </div>
    <div *ngIf="hasVerticalScrollbar" class="gcu"
         [innerHtml]="gcuContent"></div>
  </div>

  <ng-container *ngIf="!gcuDisplayType.gcuReadOnly">
    <div class="form-check mb-4">
      <input class="form-check-input" type="checkbox" id="chkbAgree" [checked]="hasAcceptedGcu"
             (change)="acceptGcuChange($event)" [disabled]="!hasVerticalScrollbar && !allGcuHasBeenRead">
      <label class="form-check-label" for="chkbAgree" themeTranslate>
        gcu.checkbox-label
      </label>
    </div>
    <button class="btn btn-primary" (click)="acceptGcu()"
            [disabled]="(hasVerticalScrollbar && !hasAcceptedGcu) || (!hasVerticalScrollbar && (!hasAcceptedGcu || !allGcuHasBeenRead))"
            themeTranslate>gcu.accept-button
    </button>
  </ng-container>

</div>
