import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { EventEmitter, Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { CoreModule } from '@core/core.module';
import { environment } from '@env/environment.prod';
import { ArrayUtils, MapUtils, ObjectUtils, StringUtils } from '@ingroupe/common-utils';
import { ThemeConfiguration } from '@models/theme/theme-configuration.model';
import { Theme } from '@models/theme/theme.model';
import { DeviceService } from '@services/device/device.service';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';

/**
 * The theme service
 */
@Injectable(
    {
        providedIn: CoreModule
    }
)
export class ThemeService {
    /**
     * The renderer to manipulate DOM
     */
    private renderer: Renderer2;

    /**
     * The head html tag
     */
    private head: HTMLElement;

    /**
     * The theme cache map
     */
    private themeMap: Map<string, HTMLLinkElement>;

    /**
     * The theme configuration
     */
    private themeConfiguration: any;

    /**
     * The default theme link tag
     */
    private defaultThemeCssLink: HTMLLinkElement;

    /**
     * The default theme
     */
    private defaultTheme: Theme;

    /**
     * Event emitter to notify when the theme has been changed
     */
    onThemeChanged: EventEmitter<Theme>;

    constructor(
        private rendererFactory: RendererFactory2,
        private http: HttpClient,
        @Inject(DOCUMENT) private document: Document,
        private deviceService: DeviceService,
        private title: Title
    ) {
        this.onThemeChanged = new EventEmitter<Theme>();
        this.head = document.head;
        this.renderer = rendererFactory.createRenderer(null, null);
        this.themeMap = new Map<string, HTMLLinkElement>();
    }

    /**
     * Load theme configuration
     *
     * @return Observable of boolean to indicate if is loaded
     */
    private loadThemeConfiguration(): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            const defaultThemeConfig: ThemeConfiguration = {
                title: 'IN CONNECT',
                favicon: 'favicon.ico'
            };

            this.http.get<any>(environment.appThemeConfigurationPath).pipe(
                catchError((error: HttpErrorResponse): any => {
                    const rejectMessage = 'The theme configuration file could not be read';
                    console.warn(rejectMessage);
                    this.themeConfiguration = {
                        config: defaultThemeConfig
                    };
                    return of<never>();
                }))
                .toPromise().then((config: any) => {
                    this.themeConfiguration = config;
                    if (ObjectUtils.isNullOrUndefined(this.themeConfiguration)) {
                        this.themeConfiguration = {
                            config: defaultThemeConfig
                        };
                    } else if (ObjectUtils.isNullOrUndefined(this.themeConfiguration.config)) {
                        this.themeConfiguration.config = defaultThemeConfig;
                    }

                    resolve(true);
                });
        });
    }

    /**
     * Configure theme
     *
     * @returns promise
     */
    public async configureTheme(): Promise<any> {
        try {
            await this.loadThemeConfiguration();
            await this.loadDefaultTheme();

            if (!this.deviceService.isDeprecatedBrowser()) {
                if (ObjectUtils.isNotNullOrUndefined(this.themeConfiguration.config)) {
                    MapUtils.fromObject(this.themeConfiguration.config).forEach(async (config: ThemeConfiguration, name: string) => {
                        await this.registerTheme(name, false);
                    });
                }
            }

            // try to get the theme for given client id otherwise get active or default theme name
            let activeThemeName: string = this.getThemeNameForClientId();
            if (StringUtils.isBlank(activeThemeName)) {
                activeThemeName = StringUtils.defaultIfBlank(this.themeConfiguration.active, this.defaultTheme.name);
            }

            if (ObjectUtils.isNullOrUndefined(this.themeMap.get(activeThemeName))
                && !StringUtils.equalsIgnoreCase(activeThemeName, this.defaultTheme.name)) {
                await this.registerTheme(activeThemeName);
            }

            this.enable(activeThemeName);

            if (!StringUtils.equals(activeThemeName, this.defaultTheme.name)
                && ObjectUtils.isNotNullOrUndefined(this.defaultThemeCssLink)) {
                this.defaultThemeCssLink.disabled = false;
            }

            return Promise.resolve();
        } catch (e) {
            Promise.reject(e);
        }
    }

    /**
     * Get the theme name for client id
     *
     * @returns the theme name
     */
    private getThemeNameForClientId(): string {
        const params: URLSearchParams = new URLSearchParams(window.location.search);
        const clientId: string = params.get('client_id');
        if (StringUtils.isNotBlank(clientId)) {
            const themeMapping: Map<string, Array<string>> = this.themeConfiguration.themeMapping;
            if (MapUtils.isNotEmpty(themeMapping)) {
                for (const [key, value] of Object.entries(themeMapping)) {
                    if (ArrayUtils.contains(value, clientId)) {
                        return key;
                    }
                }
            }
        }

        return null;
    }

    /**
     * Load the default theme ink file
     *
     * @returns Promise of loaded theme
     */
    private async loadDefaultTheme(): Promise<void> {
        return new Promise((resolve, reject) => {
            if (ObjectUtils.isNotNullOrUndefined(this.defaultThemeCssLink)) {
                reject('The default theme already loaded');
                return;
            }

            const defaultThemeName: string = StringUtils.defaultIfBlank(this.themeConfiguration.default, 'default');
            this.defaultTheme = {
                name: defaultThemeName,
                config: this.themeConfiguration.config[defaultThemeName]
            };

            this.defaultThemeCssLink = this.addCssLink(this.defaultTheme.name, resolve, false);
            if (ObjectUtils.isNullOrUndefined(this.defaultTheme.config)) {
                // define base theme configuration
                this.defaultTheme.config = {
                    footer: {
                      clientLogo: 'assets/images/footer/logo/client-default.svg',
                      manufacturerLogo: 'assets/images/footer/logo/ingroupe-default.png'
                    },
                    header: {
                        logo: 'assets/images/header/logo/default.svg'
                    },
                    title: 'Téléservice Signature électronique',
                    favicon: 'favicon.ico'
                };
            }

            resolve();
        });
    }

    /**
     * Enable only specified theme name
     *
     * @param themeName : the theme name to activate
     */
    public enable(themeName: string): void {
        let theme: HTMLLinkElement = this.themeMap.get(themeName);
        if (ObjectUtils.isNullOrUndefined(theme) && themeName === this.defaultTheme.name) {
            theme = this.defaultThemeCssLink;
        }

        if (ObjectUtils.isNotNullOrUndefined(theme)) {
            this.disableAll();

            theme.disabled = false;
            let config: ThemeConfiguration = this.themeConfiguration.config[themeName];
            if (ObjectUtils.isNullOrUndefined(config)) {
                config = this.defaultTheme.config;
            }

            this.title.setTitle(config.title);

            if (StringUtils.isNotBlank(config.favicon)) {
                const faviconEl: HTMLLinkElement = this.document.querySelector('#favicon') as HTMLLinkElement;
                if (ObjectUtils.isNotNullOrUndefined(faviconEl)) {
                    faviconEl.href = config.favicon;
                }
            }

            this.onThemeChanged.emit({
                name: themeName,
                config
            });
        }
    }

    /**
     * Disable all themes
     */
    private disableAll(): void {
        this.themeMap.forEach((linkCss, key) => {
            linkCss.disabled = true;
        });
    }

    /**
     * Get all registered theme name
     *
     * @returns Array of registered theme names
     */
    public getAllRegisteredTheme(): Array<string> {
        const registeredThemeArray: Array<string> = new Array<string>();
        registeredThemeArray.push(this.defaultTheme.name);

        this.themeMap.forEach((linkCss, key) => {
            registeredThemeArray.push(key);
        });

        return registeredThemeArray;
    }

    /**
     * Get the current active theme
     *
     * @returns the current active theme
     */
    public getActiveTheme(): Theme {
        let themeName: string = this.defaultTheme.name;
        for (const [name, cssLink] of this.themeMap) {
            if (!cssLink.disabled) {
                themeName = name;
                break;
            }
        }

        return {
            name: themeName,
            config: this.themeConfiguration.config[themeName] || this.defaultTheme.config
        };
    }

    /**
     * Get the default theme
     *
     * @returns the default theme
     */
    public getDefaultTheme(): Theme {
        return this.defaultTheme;
    }

    /**
     * Register the theme css file
     *
     * @param themeName : the theme name to load
     * @returns Promise of any
     */
    public async registerTheme(themeName: string, active: boolean = true): Promise<void> {
        return new Promise((resolve, reject) => {
            if (StringUtils.equalsIgnoreCase(themeName, this.defaultTheme.name)) {
                reject('The default theme already loaded');
                return;
            }

            let linkEl: HTMLLinkElement = this.themeMap.get(themeName);
            if (ObjectUtils.isNullOrUndefined(linkEl)) {
                linkEl = this.addCssLink(themeName, resolve, active);
                this.disableAll();
                this.themeMap.set(themeName, linkEl);
            } else {
                this.enable(themeName);
                resolve();
            }

            this.onThemeChanged.emit(this.getActiveTheme());
        });
    }

    /**
     * Add the ink link into the head tag for specified theme
     *
     * @param themeName: The theme name to load
     * @param resolve: The resolve callback to invoke when ink link element is added
     */
    private addCssLink(themeName: string, resolve: any, active: boolean = true): HTMLLinkElement {
        const url = `${StringUtils.format(environment.appCustomThemePath, themeName)}.css`;
        const linkEl: HTMLLinkElement = this.renderer.createElement('link');
        linkEl.href = url;
        linkEl.type = 'text/css';
        linkEl.rel = 'stylesheet';
        linkEl.onload = resolve;

        if (!active) {
            linkEl.disabled = true;
        }

        this.renderer.appendChild(this.head, linkEl);
        return linkEl;
    }
}
