<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li>
      <a routerLink="signature/dashboard"><i class="fas fa-home mr-1"></i></a>
    </li>
    <li class="breadcrumb-item">
      <a routerLink="signature/dashboard">{{ (isAuthenticated ? 'breadcrumb.dashboard' : 'breadcrumb.login') | themeTranslate | capitalizeFirst }}</a></li>
    <li class="breadcrumb-item active" aria-current="page">{{'breadcrumb.contact' | themeTranslate | capitalizeFirst}}</li>
  </ol>
</nav>

<h2 class="mb-3 demi">{{'contact.title' | themeTranslate | capitalizeFirst}}</h2>
<div>
  <p>
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam dapibus lobortis orci, vel maximus tortor vehicula
    id. Cras a orci id mauris iaculis tempus. Nunc rutrum fermentum finibus. Praesent ut enim eget massa luctus
    maximus. Donec egestas eget nibh nec congue. Fusce consequat sollicitudin dui in posuere. Proin venenatis et
    dolor nec convallis. Duis tellus felis, molestie eget ipsum in, ultricies tempus erat.
  </p>
  <p>
    Nulla facilisi. Morbi vehicula lorem et nunc dapibus porttitor vitae quis dolor. Fusce dictum sapien tortor, sit
    amet porta diam mollis ut. In consectetur ante at varius dignissim. Cras quis aliquet purus. Pellentesque
    habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. In congue tincidunt
    consectetur. Phasellus sed sollicitudin mauris. Mauris sit amet suscipit mi.
  </p>
  <p>
    Aenean est justo, tristique ac massa a, tempor luctus purus. Quisque in risus sed augue bibendum rutrum eget
    vitae dolor. Etiam nec commodo augue. Ut nec mollis mauris, id cursus diam. Sed rutrum lobortis quam et
    pulvinar. Nunc non tellus luctus, lacinia elit nec, lobortis mauris. Aenean semper risus sed dui malesuada
    porta. Donec et posuere nulla, vitae molestie ipsum. Pellentesque habitant morbi tristique senectus et netus et
    malesuada fames ac turpis egestas. Pellentesque blandit nisi nulla, vel lobortis tellus rutrum non. Proin auctor
    nunc ultricies ante porttitor, ut congue tellus auctor. Sed porttitor urna ac volutpat accumsan. Duis luctus
    tristique mi vitae condimentum. Maecenas purus magna, volutpat ut dapibus eget, bibendum sed mauris. Phasellus
    pretium vitae purus accumsan iaculis. Morbi ullamcorper, mauris ac venenatis ultricies, velit augue suscipit
    mauris, vitae pulvinar leo eros ac velit. Aliquam erat volutpat. Nunc at leo eros.
  </p>
  <p>
    Nulla ex ligula, ornare vitae nisl non, accumsan mollis quam. Duis ut vulputate sapien. Maecenas ac neque
    posuere, pharetra enim in, ornare erat. Maecenas mattis sagittis convallis. Sed congue nunc nisi, ut feugiat mi
    bibendum faucibus. Mauris consectetur sem a mauris gravida, quis accumsan dolor imperdiet. Vestibulum mollis
    vestibulum ex ac interdum. Etiam in sapien imperdiet, porta erat eget, efficitur leo.
  </p>
</div>


