import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { LanguageSelectorDisplayType } from '@ingroupe/translate';
import { UserInfo } from '@models/user/user-info.model';
import { LayoutService } from '@services/layout/layout.service';
import { UserService } from '@services/user/user.service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

@AutoUnsubscribe()
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, OnDestroy {
  /**
   * The logo path to display
   */
  @Input()
  logo: string;
  /**
   * Show / hide user informations
   */
  displayUserInfo: boolean;
  /**
   * The current user informations
   */
  userInfo: UserInfo;
  /**
   * The logout url
   */
  logoutUrl: string;
  /**
   * The language selector type enum
   */
  languageSelectorDisplayType = LanguageSelectorDisplayType;
  /**
   * Show / hide sidebar menu
   */
  displaySidebarMenu = false;

  /**
   * Subscription to listen user info changes
   */
  private displayUserInfoChangedSubscription: Subscription;


  /**
   * Constructor
   */
  public constructor(private userService: UserService, private layoutService: LayoutService, private router: Router,
                     private i18nService: TranslateService) {
  }

  /**
   * On component initialization listener
   */
  public ngOnInit(): void {
    this.logoutUrl = this.userService.getLogoutUrl();
    this.userInfo = this.userService.getUserInfo();
    this.displayUserInfo = this.userService.isAuthenticated();

    this.initLangFromLocale();
    this.displayUserInfoChangedSubscription = this.layoutService.displayUserInfoChanged.subscribe((display: boolean) => {
      if (display) {
        this.userInfo = this.userService.getUserInfo();
      }

      this.displayUserInfo = display && this.userService.isAuthenticated();
    });
  }

  /**
   * On component destroy listener
   */
  public ngOnDestroy(): void {
  }

  /**
   * Logout action
   */
  public logout(): void {
    window.location.href = this.logoutUrl;
  }

  /**
   * Open the sidebar menu
   */
  public openSidebarMenu(): void {
    this.displaySidebarMenu = true;
  }

  initLangFromLocale(): void {
    const browserLand = this.i18nService.getBrowserCultureLang() || this.i18nService.getDefaultLang() || 'fr';
    if (browserLand === 'fr' || browserLand === 'fr-CA' || browserLand === 'fr-CH'){
      this.i18nService.use('fr');
    }
    if (browserLand === 'en' || browserLand === 'en-GB' || browserLand === 'en-US'){
      this.i18nService.use('en');
    }
  }
  public triggerChangeLang($event): void {
    if ($event.target && $event.target.textContent === 'FRENCH'){
      this.i18nService.use('fr');
    }
    if ($event.target && $event.target.textContent === 'ANGLAIS'){
      this.i18nService.use('en');
    }
  }
  /**
   * Close the sidebar menu
   */
  public closeSidebarMenu(): void {
    this.displaySidebarMenu = false;
  }

  public gotoDashboard(): void {
    this.router.navigate(['/signature/dashboard']);
  }
}
