<div>
  <div class="guest-home d-flex flex-column" *ngIf="!errorCode">
    <div>
      <h1>{{'guest.home.title' | themeTranslate | capitalizeFirst}}</h1>
      <div class="d-flex flex-row sign-description">
        <div>{{'guest.home.description-line-1-part-1' | themeTranslate | capitalizeFirst}}</div>
        <div class="bold">{{document.commonName}}</div>
        <div>{{'guest.home.description-line-1-part-2' | themeTranslate}}</div>
        <div class="bold">{{document.filename}}.pdf.</div>
      </div>
      <div *ngIf="!isAuthenticated">{{'guest.home.description-line-2' | themeTranslate | capitalizeFirst}}</div>
    </div>

    <div>
      <a [href]="validationCodeUrl" class="btn btn-link btn-primary">
        <span *ngIf="!isAuthenticated">{{'guest.home.button.inConnect' | themeTranslate | capitalizeFirst}}</span>
        <span *ngIf="isAuthenticated">{{'guest.home.button.next' | themeTranslate | capitalizeFirst}}</span>
      </a>
    </div>
  </div>

  <div class="guest-home d-flex flex-column" *ngIf="errorCode">
    <h1>{{'guest.home.title' | themeTranslate | capitalizeFirst}}</h1>
    <div [ngSwitch]="errorCode">
      <div *ngSwitchCase="errorCodes.DOCUMENT_COMPLETED">{{'guest.home.description-already-closed' | themeTranslate |
        capitalizeFirst}}</div>
    </div>
    <a [href]="loginUrl"><span class="btn btn-link btn-primary" *ngIf="!isAuthenticated">{{'guest.home.button.inConnect'
        | themeTranslate | capitalizeFirst}}</span></a>
  </div>
</div>

<div class="row d-flex align-items-start mt-5">
  <div class="row d-flex flex-xl-row-reverse align-items-center col-12 no-gutters">
    <img class="img-fluid col-12 col-xl-7" src="assets/images/background-img/home-findOutMore.jpg" />
    <div class="description about col-12 col-xl-5 py-xl-3 px-xl-5">
      <h2 class="demi my-3">{{'guest.home.about-ESignature.title' | themeTranslate}}</h2>
      <p class="my-3 py-xl-0" innerHTML="{{'guest.home.about-ESignature.content' | themeTranslate | capitalizeFirst}}"></p>
      <div class="text-left text-md-center">
        <a href="{{'guest.home.button.learnMore-link' | themeTranslate}}" target="_blank"
           class="btn btn-link btn-primary">{{'guest.home.button.learnMore' | themeTranslate}}</a>
      </div>
    </div>
  </div>
</div>
