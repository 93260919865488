<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a
      routerLink="/signature/dashboard"><i
      class="fas fa-home mr-1"></i>{{'breadcrumb.dashboard' | themeTranslate | capitalizeFirst}}</a></li>
    <li class="breadcrumb-item" *ngIf="!isGuest"><a class="breadcrumb-link"
                                                    (click)="goToUpload()">{{'breadcrumb.upload' | themeTranslate | capitalizeFirst}}</a>
    </li>
    <li class="breadcrumb-item" *ngIf="!isGuest"><a (click)="goToRename()"
                                                    *ngIf="!isGuest">{{'breadcrumb.naming' | themeTranslate | capitalizeFirst}}</a>
    </li>
    <li class="breadcrumb-item"><a (click)="goToBack()">{{'breadcrumb.preview' | themeTranslate | capitalizeFirst}}</a>
    </li>
    <li class="breadcrumb-item active" aria-current="page">{{'breadcrumb.sign' | themeTranslate | capitalizeFirst}}</li>
  </ol>
</nav>

<h2>{{'identification-by-card.title' | themeTranslate | capitalizeFirst}}</h2>

<ng-container *ngTemplateOutlet="displayPinView ? pinView : startView"></ng-container>

<ng-template #startView>
  <div class="d-flex mt-3">
    <img class="img-fluid" src="assets/images/icons/svg/card-reader.svg" alt="icon-card-reader">
    <div class="flex-column align-self-center ml-3">
      <div>{{'identification-by-card.startView.instructions.line1' | themeTranslate | capitalizeFirst}}</div>
      <div class="mb-3">{{'identification-by-card.startView.instructions.line2' | themeTranslate}}</div>
    </div>
  </div>
</ng-template>

<ng-template #pinView>
  <div class="mt-3">
    <div>{{'identification-by-card.pinView.instructions.line1' | themeTranslate | capitalizeFirst}}</div>
    <div>{{'identification-by-card.pinView.instructions.line2' | themeTranslate }}</div>
  </div>
  <div class="d-flex flex-column mx-0 active-card">
    <div class="d-flex flex-row col-8 col-md-4 p-0 my-3">
      <div class="px-0 d-flex flex-column justify-content-end"
           [ngClass]="{'success': authenticationState.success, 'error': authenticationState.error !== null}">
        <code-input [code]="pin" [isCodeHidden]="isHiddenPin" [isNonDigitsCode]="false" [codeLength]="codeLength"
                    [ngClass]="{'hiddenChars': isHiddenPin}" (codeChanged)="onPinChanged()"
                    (keyup)="savePositionLost(pinField)"
                    (codeCompleted)="onPinCompleted($event)" #pinField></code-input>
      </div>

      <div class="p-0 ml-3 d-flex align-items-center justify-content-center">
        <div *ngIf="!authenticationState.pending && !authenticationState.success" class="btn-password-visibility"
             (click)="showHidePinChars()" value="toggle">
          <i class="fas" [ngClass]="{'fa-eye': isHiddenPin, 'fa-eye-slash': !isHiddenPin}"></i>
        </div>
      </div>
    </div>
    <div class="my-3 ml-3">
      <a href="{{'identification-by-card.pinView.forgotten-code-link' | themeTranslate}}" target="_blank">
        {{'identification-by-card.pinView.forgotten-code' | themeTranslate }}</a>
    </div>
  </div>
</ng-template>
