import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { SlideDirection, slideInOut } from '@shared/ui/effects/slide-in-slide-out.animation';

const SIDEBAR_ANIMATION_SPEED = 400;

/**
 * An sidebar component
 */
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: [
    slideInOut(SlideDirection.LEFT_TO_RIGHT, SIDEBAR_ANIMATION_SPEED)
  ]
})
export class SidebarComponent {
  /**
   * Show / hide the sidebar
   */
  @Input()
  display = false;

  /**
   * Display change event
   */
  @Output()
  displayChange: EventEmitter<boolean>;

  /**
   * Show / hide the sidebar
   */
  showOverlay: boolean;

  /**
   * Constructor
   */
  public constructor(private el: ElementRef) {
    this.displayChange = new EventEmitter<boolean>(this.display);
  }

  /**
   * Close the sidebar
   */
  public closeSidebar(): void {
    this.display = false;
  }

  /**
   * When sidebar open animation start
   *
   * @param animationEvent : the animation event
   */
  onAnimationStart(animationEvent: AnimationEvent): void {
    if (this.display) {
      this.showOverlay = true;
    }
  }

  /**
   * When sidebar close animation end
   *
   * @param animationEvent : the animation event
   */
  onAnimationEnd(animationEvent: AnimationEvent): void {
    if (!this.display) {
      this.showOverlay = false;
      this.displayChange.emit(this.display);
    }
  }
}
