import { EventEmitter, Injectable } from '@angular/core';
import { CoreModule } from '@core/core.module';

/**
 * The Layout service
 */
@Injectable(
    {
        providedIn: CoreModule
    }
)
export class LayoutService {
    /**
     * Indicate if the user information must be displayed or not
     */
    private displayedUserInfo: boolean;

    /**
     * Event to show hide user info
     */
    public displayUserInfoChanged: EventEmitter<boolean>;

    /**
     * Event to show hide loading spinner
     */
    public displayLoadingSpinnerChanged: EventEmitter<boolean>;

    /**
     * Constructor
     */
    public constructor() {
        this.displayUserInfoChanged = new EventEmitter<boolean>();
        this.displayLoadingSpinnerChanged = new EventEmitter<boolean>();
    }

    /**
     * Display the user informations
     *
     * @param display : indicate to show or hide user infos
     */
    public displayUserInfo(display: boolean = true): void {
        if (this.displayedUserInfo !== display) {
            this.displayUserInfoChanged.emit(display);
            this.displayedUserInfo = display;
        }
    }

    /**
     * Indicate if the user info are displayed or not
     *
     * @returns true if displayed otherwise false
     */
    public isDisplayedUserInfo(): boolean {
        return this.displayedUserInfo;
    }

    /**
     * Indicate if vertical scrollbar is displayed
     */
    public hasVerticalScrollbar(): boolean {
        return document.body.scrollHeight > document.documentElement.clientHeight;
    }

    /**
     * Display the loading spinner
     */
    public displayLoadingSpinner(): void {
        this.displayLoadingSpinnerChanged.emit(true);
    }

    /**
     * Hide the loading spinner
     */
    public hideLoadingSpinner(): void {
        this.displayLoadingSpinnerChanged.emit(false);
    }
}
