import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { LangConfigurationService } from '@services/configuration/lang-configuration.service';
import { LayoutService } from '@services/layout/layout.service';
import { AnalyticsService } from '@services/analytics/analytics.service';
import { NavigationEnd, Router } from '@angular/router';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';

@AutoUnsubscribe()
@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, AfterViewInit, OnDestroy {
  /**
   * Indicate to display loading spinner
   */
  isLoading: boolean;

  /**
   * Indicates to display cookies banner
   */
  public displayCookieBanner: boolean;

  /**
   * Indicates if analytics service is Activate
   */
  analyticsIsActive: boolean;

  /**
   * Subscription to the Router events
   */
  private routerSubscription: Subscription;

  /**
   * Subscription to analytics cookie state
   */
  private analyticsSubscription: Subscription;

  /**
   * Constructor
   */
  public constructor(private langConfigService: LangConfigurationService, private layoutService: LayoutService,
                     private analyticsService: AnalyticsService, private router: Router) {
  }

  /**
   * On init component listener
   */
  ngOnInit(): void {
    this.layoutService.displayLoadingSpinnerChanged.subscribe((display: boolean) => this.isLoading = display);
    this.displayCookieBanner = !this.analyticsService.isInformed();

    this.analyticsSubscription = this.analyticsService.cookieState.subscribe((isActive: boolean) => {
      this.analyticsIsActive = isActive;
    });


    if (this.analyticsIsActive || !this.analyticsService.isInformed()) {
      this.analyticsService.initAnalytics();
    }

    if (this.analyticsIsActive) {
      this.displayCookieBanner = false;
    }

    this.routerSubscription = this.router.events.subscribe((event) => {
      if (this.analyticsIsActive || !this.analyticsService.isInformed()) {
        if (event instanceof NavigationEnd) {
          this.analyticsService.trackPageView(event.urlAfterRedirects);
        }
      }
    });
  }

  /**
   * After init view component listener
   */
  ngAfterViewInit(): void {
    // dirty hack otherwise view not internationalized
    setTimeout(() => {
      this.langConfigService.configureDefaultLang().then();
    }, 200);
  }

  public refuseAnalytics(): void {
    this.analyticsService.deactivateAnalytics();
    this.displayCookieBanner = false;
  }

  public activateAnalytics(): void {
    this.analyticsService.activateAnalytics();
    this.displayCookieBanner = false;
  }

  ngOnDestroy(): void {
    // DON'T REMOVE THIS METHOD ! IS USED BY AutoUnsubscribe decorator !
  }


}
