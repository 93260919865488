<h1 class="mb-5 demi">{{'home.title' | themeTranslate | capitalizeFirst}}</h1>

<div class="row d-flex align-items-start">
  <div class="row d-flex flex-xl-row-reverse align-items-center col-12 col-sm-6 col-xl-12 no-gutters">
    <img class="img-fluid col-12 col-xl-7" src="assets/images/background-img/home-start.jpg" />
    <div class="description mconnect col-12 col-xl-5 py-xl-3 px-xl-5">
      <p class="my-3 py-xl-0" innerHTML="{{'home.description-mconnect' | themeTranslate | capitalizeFirst}}"></p>
      <div class="text-center">
        <a [href]="loginUrl" class="btn btn-link btn-primary" *ngIf="!isMobile">{{'home.btn.inConnect' | themeTranslate}}</a>
        <button type="button" class="btn btn-primary" *ngIf="isMobile" disabled>{{'home.btn.disabled' | themeTranslate}}</button>
      </div>
    </div>
  </div>

  <div class="row d-flex align-items-center col-12 col-sm-6 col-xl-12 no-gutters mt-5 mt-sm-0 mt-xl-5">
    <img class="img-fluid col-12 col-xl-7" src="assets/images/background-img/home-findOutMore.jpg" />
    <div class="description show-more col-12 col-xl-5 py-xl-3 px-xl-5">
      <p class="my-3 py-xl-0" innerHTML="{{'home.description-findOutMore' | themeTranslate | capitalizeFirst}}"></p>
      <div class="text-center">
        <a href="{{'home.btn.findOutMore.href' | themeTranslate}}" target="_blank"
          class="btn btn-link btn-primary">{{'home.btn.findOutMore'  | themeTranslate}}</a>
      </div>
    </div>
  </div>
</div>
