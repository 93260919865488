import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// old navigators css vars
import cssVars from 'css-vars-ponyfill';

cssVars({
  // Treat all browsers as legacy
  onlyLegacy: true,

  // observe change on link stylesheet tags
  watch: true
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
