import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {CoreModule} from '@core/core.module';
import {DocumentDto} from '@models/document/document.model';
import {DocumentService} from '@services/document/document.service';
import {ToastNotificationService} from '@services/toast-notification/toast-notification.service';

@Injectable({ providedIn: CoreModule })
export class ShareDocumentResolver implements Resolve<DocumentDto> {
    constructor(
        private documentService: DocumentService,
        private notifService: ToastNotificationService
    ) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<DocumentDto> {
        const docId: string = route.paramMap.get('id');
        return this.documentService.getDocumentMetadata(docId).catch((reason: any) => {
            this.notifService.error('share-document.error.unable-to-get-metadata');
            throw new Error('Unable to get document metadata...');
        });
    }
}
