import { DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from '@core/core.module';
import { LayoutComponent } from '@core/layout/layout.component';
import { LayoutModule } from '@core/layout/layout.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AboutComponent } from '@pages/about/about.component';
import { FaqComponent } from '@pages/faq/faq.component';
import { GcuComponent } from '@pages/gcu/gcu.component';
import { GuestHomeComponent } from '@pages/guest/home/guest-home.component';
import { HomeComponent } from '@pages/home/home.component';
import { AppRoutingModule } from '@pages/routing/app-routing.module';
import { DashboardComponent } from '@pages/signature/dashboard/dashboard.component';
import { PreviewBeforeSignComponent } from '@pages/signature/preview-before-sign/preview-before-sign.component';
import { ShareDocumentComponent } from '@pages/signature/share-document/share-document.component';
import { SignConfirmationComponent } from '@pages/signature/sign-confirmation/sign-confirmation.component';
import { RenameComponent } from '@pages/signature/upload-pdf/component/rename/rename.component';
import { UploadComponent } from '@pages/signature/upload-pdf/upload.component';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { ValidationCodeComponent } from '@pages/signature/validation-code/validation-code.component';
import { PreviewDocumentComponent } from '@pages/signature/statement/preview-document/preview-document.component';
import {
  SignalDocumentModalComponent
} from '@pages/signature/statement/preview-document/signal-document-modal/signal-document-modal.component';
import { MyDocumentsComponent } from '@pages/my-documents/my-documents.component';
import { DocumentDetailComponent } from '@pages/signature/document-detail/document-detail.component';
import {SignatureDocumentComponent} from '@pages/signature/signature-document/signature-document.component';
import {CodeInputModule} from 'angular-code-input';
import { NotificationsComponent } from '@pages/notifications/notifications.component';
import { IncompatibleBrowserComponent } from '@pages/incompatible-browser/incompatible-browser';

@NgModule({
  declarations: [
    HomeComponent,
    GcuComponent,
    AboutComponent,
    FaqComponent,
    MyDocumentsComponent,
    DashboardComponent,
    UploadComponent,
    RenameComponent,
    SignConfirmationComponent,
    PreviewBeforeSignComponent,
    GuestHomeComponent,
    ShareDocumentComponent,
    ValidationCodeComponent,
    PreviewDocumentComponent,
    SignalDocumentModalComponent,
    DocumentDetailComponent,
    SignatureDocumentComponent,
    NotificationsComponent,
    IncompatibleBrowserComponent
  ],
  imports: [
    NgxExtendedPdfViewerModule,
    NgxDropzoneModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    LayoutModule,
    BrowserAnimationsModule,
    ClipboardModule,
    ReactiveFormsModule,
    CodeInputModule
  ],
  exports: [LayoutComponent, CoreModule],
  providers: [DatePipe]
})
export class PagesModule { }
