import { HttpParams } from '@angular/common/http';
import { MapUtils, ObjectUtils } from '@ingroupe/common-utils';
import { SortDirection } from './sort-direction.enum';
import { Sort } from '@models/pageable/sort.model';

export class Pageable {
  /**
   * The page number
   */
  page = 0;

  /**
   * The page size
   */
  size = 10;

  /**
   * Object which represent filters
   */
  filters: any = {};

  /**
   * Sorts map with field name as key and sort with direction and order as value
   */
  sorts: Map<string, Sort> = new Map<string, Sort>();

  /**
   * Convert this pageable object to HttpParams
   *
   * @returns HttpParams
   */
  public toUrlParams(): HttpParams {
    let params: HttpParams = new HttpParams();
    params = params.append('page', (this.page) ? this.page.toString() : '0');
    params = params.append('size', (this.size && this.size > 0) ? this.size.toString() : '10');
    // params = params.append('sort', `modifiedDate,${(SortDirection.DESC)}`);


    if (ObjectUtils.isNotNullOrUndefined(this.filters)) {
      for (const key of Object.keys(this.filters)) {
        params = params.append(key, this.filters[key]);
      }
    }

    if (MapUtils.isNotEmpty(this.sorts)) {
      const sortedSortMap: Map<string, Sort> = new Map([...this.sorts.entries()].sort((a, b) => a[1].order - b[1].order));
      sortedSortMap.forEach((sort: Sort, field: string) => {
        params = params.append('sort', `${field}${(SortDirection.NONE !== sort.direction) ? ',' + sort.direction : ''}`);
      });
    }

    return params;
  }
}
