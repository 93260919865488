import { Component, OnDestroy, OnInit } from '@angular/core';
import { ArrayUtils, ObjectUtils, StringUtils } from '@ingroupe/common-utils';
import { I18nDomainService } from '@services/i18n/i18n-domain.service';
import { ToastNotificationService } from '@services/toast-notification/toast-notification.service';
import { ConfigurationService } from '@services/configuration/configuration.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DocumentDto } from '@models/document/document.model';
import { SessionStorageService } from '@services/storage/session-storage.service';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit, OnDestroy {

  public file: File;
  public maxFileSize: number;
  public fileType: string;
  public multipleFile: boolean;
  public documentId: string;
  public infosDocument: DocumentDto;
  public isAlreadyUploded: boolean;

  private routeDataSubscription: Subscription;

  constructor(private toastNotificationService: ToastNotificationService, private translateService: I18nDomainService,
              private config: ConfigurationService, private router: Router, private route: ActivatedRoute,
              private sessionStorageService: SessionStorageService) {

  }

  public ngOnInit(): void {
    this.routeDataSubscription = this.route.data.subscribe(data => {
      this.documentId = this.route.snapshot.paramMap.get('id');
      this.isAlreadyUploded = false;

      if (this.sessionStorageService.find('idDocument')) {
        const documentId: string = this.sessionStorageService.find('idDocument');
        this.router.navigate(['/signature/upload-pdf', documentId]);
      }

      if (StringUtils.isNotBlank(this.documentId)) {
        this.infosDocument = data.infoUploadDocument;
        this.isAlreadyUploded = true;
      }
    });

    this.maxFileSize = this.config.getConfig('uploadPdf.maxFileSize');
    this.fileType = this.config.getConfig('uploadPdf.fileType');
    this.multipleFile = this.config.getConfig('uploadPdf.multipleFile');
  }

  public ngOnDestroy(): void {
  }

  public onFileSelect(event): void {
    if (ObjectUtils.isNotNullOrUndefined(event)) {
      if (ArrayUtils.isNotEmpty(event.addedFiles)) {
        this.file = event.addedFiles[0];
      } else if (ArrayUtils.isNotEmpty(event.rejectedFiles)) {
        const reason = event.rejectedFiles[0].reason;
        switch (reason) {
          case 'type':
            this.toastNotificationService.error(this.translateService.resolveMessage('upload-pdf.upload.error.only-pdf'));
            break;
          case 'size':
            this.toastNotificationService.error(this.translateService.resolveMessage('upload-pdf.upload.error.maxsize-pdf'));
            break;
        }
      }
    }
  }

  public initPdf(): void {
    this.file = null;
    this.documentId = null;
    this.router.navigate(['/signature/upload-pdf']);
  }

  public displayRename(): boolean {
    return this.file != null || this.documentId != null;
  }
}
