import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-incompatible-browser',
  templateUrl: './incompatible-browser.html',
  styleUrls: ['./incompatible-browser.scss']
})
export class IncompatibleBrowserComponent implements OnInit {
  /**
   * Default constructor
   */
  public constructor() {

  }

  /**
   * Init page listener
   */
  public ngOnInit(): void {

  }
}
