import { Component, OnDestroy, OnInit } from '@angular/core';
import { Theme } from '@models/theme/theme.model';
import { ThemeService } from '@services/theme/theme.service';
import { UserService } from '@services/user/user.service';
import { Subscription } from 'rxjs';
import {UserInfo} from '@models/user/user-info.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalConfirmComponent } from '@components/modal-confirm/modal-confirm.component';
import { I18nDomainService } from '@services/i18n/i18n-domain.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  /**
   * The logo path to display
   */
  logo: string;

  /**
   * Indicate if the user is authenticated
   */
  isAuthenticated: boolean;

  /**
   * The on theme changed listener
   */
  private onThemeChanged: Subscription;

  /**
   * The logout url
   */
  logoutUrl: string;

  /**
   * The current user informations
   */
  userInfo: UserInfo;

  /**
   * Constructor
   *
   * @param userService : the user service
   */
  constructor(private userService: UserService, private themeService: ThemeService,
              private translateService: I18nDomainService, private modalService: NgbModal) {
    this.onThemeChanged = this.themeService.onThemeChanged.subscribe((theme: Theme) => {
      this.logo = theme.config.header.logo;
    });

    this.logo = themeService.getActiveTheme().config.header.logo;
  }

  /**
   * On init component listener
   */
  ngOnInit(): void {
    this.isAuthenticated = this.userService.isAuthenticated();
    this.logoutUrl = this.userService.getLogoutUrl();
    this.userInfo = this.userService.getUserInfo();
  }

  /**
   * Logout action
   */
  public logout(): void {
    const modalRef = this.modalService.open(ModalConfirmComponent, { centered: true, size: 'md' });
    modalRef.componentInstance.title = this.translateService.resolveMessage('header.modal-logout.title');
    modalRef.componentInstance.message = this.translateService.resolveMessage('header.modal-logout.message');
    modalRef.componentInstance.btnCancel = this.translateService.resolveMessage('header.modal-logout.btn-cancel');
    modalRef.componentInstance.btnAccept = this.translateService.resolveMessage('header.modal-logout.btn-accept');
    modalRef.result.then(() => {
      window.location.href = this.logoutUrl;
    });
  }

  /**
   * On destroy page listener
   */
  ngOnDestroy(): void {

  }
}
