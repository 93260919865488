import {ChangeDetectorRef, Inject, Pipe, PipeTransform} from '@angular/core';
import { Theme } from '@models/theme/theme.model';
import { ObjectUtils, StringUtils } from '@ingroupe/common-utils';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { ThemeService } from '@services/theme/theme.service';

@Pipe({
    name: 'themeTranslate',
    pure: false
})
export class ThemeTranslatePipe extends TranslatePipe implements PipeTransform {
    private defaultThemeName = '';

    constructor(
        private themeService: ThemeService,
        @Inject(TranslateService) private translateService: TranslateService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        super(translateService, changeDetectorRef);

        this.defaultThemeName = this.getKeyPrefix(themeService.getDefaultTheme());
    }

    /**
     * Get the key prefix in function of theme
     *
     * @param theme : the theme
     */
    private getKeyPrefix(theme: Theme): string {
        let themeName = '';
        if (ObjectUtils.isNotNullOrUndefined(theme)) {
            themeName = theme.name;
            if (StringUtils.isNotBlank(themeName)) {
                themeName += '.';
            }
        }

        return themeName;
    }

    transform(key: string, ...args: any[]): any {
        let themeKeyPrefix: string = this.getKeyPrefix(this.themeService.getActiveTheme());
        if (StringUtils.isBlank(themeKeyPrefix)) {
            themeKeyPrefix = this.defaultThemeName;
        }

        let value: string = super.transform(`${key}`, args);
        if (!StringUtils.equals(themeKeyPrefix, this.defaultThemeName)
            && (!value || value === `${themeKeyPrefix}${key}`)) {
            value = super.transform(`${this.defaultThemeName}${key}`, args);
            if (!value || value === `${this.defaultThemeName}${key}`) {
                value = super.transform(key, args) || key;
            }
        }
        return value;
    }
}
