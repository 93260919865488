import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {UserService} from '@services/user/user.service';
import {IngroupeTranslateService} from '@ingroupe/translate';
import {DocumentInfoService} from '@services/document/document-info.service';
import {ActivatedRoute, Router} from '@angular/router';
import {StringUtils} from '@ingroupe/common-utils';
import {ConfigurationService} from '@services/configuration/configuration.service';
import {ErrorCodes} from '@models/error/error-codes.enum';
import {PublicDocument} from '@models/document/public-document.model';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';

@AutoUnsubscribe()
@Component({
  selector: 'app-guest-home',
  templateUrl: './guest-home.component.html',
  styleUrls: ['./guest-home.component.scss']
})
export class GuestHomeComponent implements OnInit, OnDestroy {
  private onChangeLanguageSubscription: Subscription;
  private dataSubscription: Subscription;
  public validationCodeUrl: string;
  public loginUrl: string;
  public isAuthenticated: boolean;
  public document: PublicDocument;
  public errorCode: ErrorCodes;
  public errorCodes = ErrorCodes;

  public constructor(private userService: UserService, private translateService: IngroupeTranslateService,
                     private documentInfoService: DocumentInfoService, private activatedRoute: ActivatedRoute,
                     private router: Router, private configService: ConfigurationService, private route: ActivatedRoute) {
  }

  /**
   * Component initialization listener
   */
  ngOnInit(): void {
    const documentId: string = this.route.snapshot.paramMap.get('id');
    this.validationCodeUrl = StringUtils.format(
      this.configService.getEndPoint('auth.guestLogin'), documentId, this.translateService.getCurrentLang()
    );
    this.loginUrl = this.userService.getLoginUrl(this.translateService.getCurrentLang());

    this.isAuthenticated = this.userService.isAuthenticated();

    this.onChangeLanguageSubscription = this.translateService.onLanguageChange.subscribe((nextLang: string) => {
      this.validationCodeUrl = StringUtils.format(this.configService.getEndPoint('auth.guestLogin'), documentId, nextLang);
      this.loginUrl = this.userService.getLoginUrl(nextLang);
    });

    this.dataSubscription = this.route.data.subscribe(data => {
      if (data.info.error) {
        this.errorCode = data.info.error;
      } else {
        this.document = data.info.document;
      }
    });
  }

  /**
   * On destroy component listener
   */
  public ngOnDestroy(): void {
    // DON'T REMOVE THIS METHOD ! AutoUnsubscribe use it !
  }

}
