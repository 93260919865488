import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {CoreModule} from '../core.module';
import {DeviceService} from '../services/device/device.service';

/**
 * The browser guard to route to incompatible navigator page
 */
@Injectable({
    providedIn: CoreModule
})
export class BrowserGuard implements CanActivate {
    /**
     * Constructor
     *
     * @param deviceService : the device service to check the browser compatibility
     * @param router : the router to route to incompatible navigator page
     */
    constructor(private deviceService: DeviceService, private router: Router) {

    }

    /**
     * Guard route activation check
     */
    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): UrlTree | boolean {
        const isDeprecatedBrowser: boolean = this.deviceService.isDeprecatedBrowser();
        if (isDeprecatedBrowser) {
            return this.router.parseUrl('/incompatible-browser');
        }

        return true;
    }
}
