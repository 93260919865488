import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { StringUtils } from '@ingroupe/common-utils';
import { DocumentDto } from '@models/document/document.model';
import { DocumentService } from '@services/document/document.service';
import { LayoutService } from '@services/layout/layout.service';
import { ToastNotificationService } from '@services/toast-notification/toast-notification.service';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { ClipboardService, IClipboardResponse } from 'ngx-clipboard';
import { Subscription } from 'rxjs';
import { I18nDomainService } from '@services/i18n/i18n-domain.service';

@AutoUnsubscribe()
@Component({
  selector: 'app-share-document',
  templateUrl: './share-document.component.html',
  styleUrls: ['./share-document.component.scss']
})
export class ShareDocumentComponent implements OnInit, OnDestroy {
  /**
   * The route data subsciption
   */
  private routeDataSubscription: Subscription;

  /**
   * The current document id
   */
  private documentId: string;

  /**
   * The shared document metadata
   */
  sharedDocument: DocumentDto;

  /**
   * The share form
   */
  shareForm: FormGroup;

  /**
   * Direct access to form controls
   */
  controls: {
    [key: string]: AbstractControl;
  };

  /**
   * data for building the Mail
   */
  sharedMail: string;

  /**
   * notification message display only if notification not activated already
   */
  showNotificationBanner: boolean;

  public constructor(
    private route: ActivatedRoute,
    private router: Router,
    private layoutService: LayoutService,
    private clipboardService: ClipboardService,
    private toastNotificationService: ToastNotificationService,
    private documentService: DocumentService,
    private formBuilder: FormBuilder,
    private translateService: I18nDomainService
  ) {
    this.shareForm = this.formBuilder.group({
      expectedSignatureCount: ['1', [Validators.required, Validators.min(1), Validators.max(9)]],
    });

    this.controls = this.shareForm.controls;
  }

  @HostListener('input', ['$event'])
  public handleKeyboardEvent(event): void {
    if (event.target.id === 'expectedSignatureCount' && event.data === '0') {
      this.controls.expectedSignatureCount.reset(1);
    }
  }

  /**
   * On init component listener
   */
  public ngOnInit(): void {
    this.routeDataSubscription = this.route.data.subscribe(data => {
      this.documentId = this.route.snapshot.paramMap.get('id');
      this.sharedDocument = data.sharedDocument;
      this.sharedDocument.expectedSignatureCount = (this.sharedDocument.expectedSignatureCount >= 1)
        ? this.sharedDocument.expectedSignatureCount : 1;
      this.sharedDocument.accessUrl = this.buildAccesUrl();
      this.controls.expectedSignatureCount.setValue(this.sharedDocument.expectedSignatureCount);
      this.showNotificationBanner = StringUtils.isBlank(data.notification.email);

      const subjectMail = this.translateService.resolveMessage('document-detail.info-to-signatories.subject-mail');
      this.sharedMail = `mailto:?subject=${subjectMail}&body=${this.sharedDocument.accessUrl}`;
    });

    this.clipboardService.configure({
      cleanUpAfterCopy: true
    });

    this.clipboardService.copyResponse$.subscribe((response: IClipboardResponse) => {
      if (response.isSuccess) {
        this.toastNotificationService.success('share-document.success.data-copied');
      }
    });
  }

  /**
   * On destroy component listener
   */
  public ngOnDestroy(): void {

  }

  /**
   * Copy the code on click
   */
  public copyCode(): void {
    this.clipboardService.copy(this.sharedDocument.accessCode);
  }

  /**
   * Copy the acces url on click
   */
  public copyUrl(): void {
    this.clipboardService.copy(this.sharedDocument.accessUrl);
  }

  /**
   * Add or remove signatories
   *
   * @param add : the number to add
   */
  public setExpectedSignatoriesCount(add: number): void {
    let newValue = 1;
    if (StringUtils.isNotBlank(`${this.controls.expectedSignatureCount.value}`)) {
      newValue = parseInt(`${this.controls.expectedSignatureCount.value}`, 10) + add;
    }

    if (newValue >= 1 && newValue < 10) {
      this.controls.expectedSignatureCount.setValue(newValue);
    }
  }

  /**
   * update the number of signatories
   */
  public updateSignatoriesNumber(): void {
    if (this.controls.expectedSignatureCount.value > 0) {
      this.documentService.updateExpectedSignatureCount(this.documentId, this.controls.expectedSignatureCount.value)
        .then(() => {
          this.toastNotificationService.success('share-document.success.updateSignatoriesNumber');
          this.refreshDatas();
        })
        .catch(() => {
          this.toastNotificationService.error('share-document.error.updateSignatoriesNumber');
        });
    }
  }

  /**
   * Get details of a document
   */
  public refreshDatas(): void {
    this.documentService.getDocumentMetadata(this.documentId).then(response => {
      this.sharedDocument = response;
      this.sharedDocument.accessUrl = this.buildAccesUrl();
    });
  }

  private buildAccesUrl(): string {
    return `${window.location.protocol}//${window.location.host}/guest/home/${this.documentId}`;
  }

}
