import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularUtils, ArrayUtils, ObjectUtils, StringUtils } from '@ingroupe/common-utils';
import { DocumentList } from '@models/document/document-list';
import { StatusFilter } from '@models/document/status-filter.enum';
import { SortDirection } from '@models/pageable/sort-direction.enum';
import { DocumentService } from '@services/document/document.service';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { MyDocumentsFilterHandlerComponent } from './my-documents-filter-handler.component';
import { Pageable } from '@models/pageable/pageable.model';
import { DeviceService } from '@services/device/device.service';

@Component({
  selector: 'app-my-documents',
  templateUrl: './my-documents.component.html',
  styleUrls: ['./my-documents.component.scss']
})
export class MyDocumentsComponent extends MyDocumentsFilterHandlerComponent {
  public documents: DocumentList;
  public isCollapsed: boolean;
  public sortDirection = SortDirection;
  public readonly STATUS_LIST = Object.values(StatusFilter).filter(status => status !== StatusFilter.SOON_EXPIRED);
  public readonly STATUS_SOON_EXPIRED = StatusFilter.SOON_EXPIRED;
  public isMobile: boolean;

  @ViewChild('filename', { static: true }) inputFilename: ElementRef;
  @ViewChild('modifiedDateDesc', { static: true }) inputModifiedDateDesc: ElementRef;
  @ViewChild('creatorCodeMe', { static: true }) inputCreatorCodeMe: ElementRef;
  @ViewChild('creatorCodeOther', { static: true }) inputCreatorCodeOther: ElementRef;

  constructor(private documentService: DocumentService, router: Router,
              private route: ActivatedRoute, private deviceService: DeviceService) {
    super(route, router);
    this.sortCriteriaChange('modifiedDate', SortDirection.DESC);
    this.isMobile = this.deviceService.isMobile();
  }

  public search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      map((term: string) => {
        if (term.length > 2 || term.length === 0) {
          this.filterByDocumentName(term);
        }
      })
    );

  /**
   *  Get the list of documents by name filter
   *
   * @param name: search value
   */
  public filterByDocumentName(name: string): void {
    this.pagination.filters.partialFilename = name;
    this.getDocumentsList(1);
  }

  /**
   * Get the list of documents
   *
   * @param page: the landing page
   */
  public getDocumentsList(page?: number): void {
    // hack used to avoid first load fired by page changed event
    if (!this.initialized) {
      return;
    }

    if (ObjectUtils.isNotNullOrUndefined(page)) {
      this.pagination.page = page - 1;
    }

    this.documentService.getDocumentsList(this.pagination).then(response => {
      this.documents = response;
      this.documents.pageable.pageNumber += 1;
    });
  }

  /**
   * Method used to sorting the list of documents
   *
   * @param criteriaName: the name of the sorting
   * @param sortDir: the direction of the sorting
   */
  public sortCriteriaChange(criteriaName: string, sortDir: SortDirection): void {
    if (SortDirection.NONE === sortDir) {
      this.pagination.sorts.delete(criteriaName);
    } else {
      this.pagination.sorts.clear();
      this.toggleSortType(criteriaName);
      this.pagination.sorts.set(criteriaName,
        { direction: sortDir, order: (StringUtils.equalsIgnoreCase(criteriaName, 'modifiedDate') ? -1 : 0) });
    }

    this.getDocumentsList(1);
  }

  /**
   * Disables concurrent sorting
   *
   * @param criteriaName: name of the selected radio button
   */
  public toggleSortType(criteriaName: string): void {
    if (criteriaName === 'filename') {
      this.filterSort.modifiedDate = undefined;
    } else {
      this.filterSort.filename = undefined;
    }
  }

  /**
   * Method used to filter the list of documents
   *
   * @param target: The selected item in the template
   */
  public filter(target: EventTarget | HTMLElement): void {
    const filterField: HTMLInputElement = target as HTMLInputElement;
    const filterName: string = filterField.name;

    let value: any = (filterField.checked) ? filterField.value : null;
    // manage array of values for checkboxes inputs
    if (filterField.type.toLowerCase() === 'checkbox') {
      value = this.pagination.filters[filterName];
      if (ArrayUtils.isEmpty(value)) {
        value = [];
      }
      if (filterField.checked) {
        value.push(filterField.value);
      } else {
        value.splice(value.indexOf(filterField.value), 1);
      }
    }

    // if filter is null or undefined or empty array then remove filter otherwise set the filter
    if ((value instanceof Array && ArrayUtils.isEmpty(value))
      || ObjectUtils.isNullOrUndefined(value)) {
      delete this.pagination.filters[filterName];
    } else {
      this.pagination.filters[filterName] = value;
    }

    this.getDocumentsList(1);
  }

  /**
   * Navigate to the detail of the selected document
   *
   * @param documentId: the document identification code
   */
  public navigateToDetail(documentId: string): void {
    this.router.navigate(['document-detail', documentId]);
  }

  /**
   * Untag an filter
   *
   * @param tagCat: Category of the filter
   * @param tagValue: The filter's value
   * @param sort: indicate if the tag is sort or not
   */
  public untag(tagCat: string, tagValue: string, sort: boolean): void {
    // remove tag related filter or sort
    if (sort) {
      this.sortCriteriaChange(tagCat, SortDirection.NONE);
      this.filterSort[tagCat] = null;
    }

    // uncheck filter or sort input element
    const nodeList: NodeListOf<HTMLElement> = document.getElementsByName(tagCat);
    nodeList.forEach((el: HTMLInputElement) => {
      if (el.value === tagValue) {
        el.checked = false;

        if (!sort) {
          this.filter(el);
        }
      }
    });
  }

  /**
   * colorization of the badge according to the status of the document
   *
   * @param status: the status of the document
   */
  public formatBadge(status): string {
    switch (status) {

      case StatusFilter.CANCELED:
        return 'badge-warning';

      case StatusFilter.ALL_SIGNED:
        return 'badge-success';

      default:
        return 'badge-primary';
    }
  }

  public initFilters(): void {
    const statusNodeList: NodeListOf<HTMLElement> = document.getElementsByName('status');
    statusNodeList.forEach((el: HTMLInputElement) => {
      el.checked = false;
    });

    this.inputCreatorCodeMe.nativeElement.checked = false;
    this.inputCreatorCodeOther.nativeElement.checked = false;

    this.filterSort = { filename: undefined, modifiedDate: SortDirection.DESC };
    this.pagination = new Pageable();

    this.sortCriteriaChange('modifiedDate', SortDirection.DESC);
    AngularUtils.runAtNextTick(() => {
      this.inputModifiedDateDesc.nativeElement.checked = true;
    });
  }
}
