<div class="py-1 px-3 m-0 d-none d-md-flex justify-content-center no-gutters">
  <div class="header-link col-12 col-lg-8 d-flex flex-sm-row justify-content-sm-between">
    <a routerLink="/signature/dashboard">
      <img [src]="logo" class="img-fluid mx-auto align-self-center" alt="">
      {{'header.title' | themeTranslate}}
    </a>
  </div>
</div>
<header class="w-100 p-2 d-none d-md-flex flex-shrink-0 flex-column">
  <div class="row h-100 p-3 m-0 d-flex justify-content-center no-gutters">
    <div class="col-12 col-lg-8 d-flex flex-sm-row justify-content-sm-between">
      <a [routerLink]="isAuthenticated ? 'signature/dashboard' : '/home'">
        <span class="demi">{{ 'header.service-name' | themeTranslate }}</span>
      </a>
      <div class="d-flex align-items-end justify-content-center login-panel" *ngIf="isAuthenticated">
        <span>{{userInfo.given_name}} {{userInfo.family_name}}</span>
        <span class="ml-1 mr-1">-</span>
        <a (click)="logout()" themeTranslate>header.menu.logout</a>
      </div>
    </div>
  </div>
</header>

