import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { StringUtils } from '@ingroupe/common-utils';
import { NgForm } from '@angular/forms';
import { ToastNotificationService } from '@services/toast-notification/toast-notification.service';
import { UploadPdfService } from '@services/document/upload-pdf.service';
import { I18nDomainService } from '@services/i18n/i18n-domain.service';
import { DocumentDto } from '@models/document/document.model';
import { SessionStorageService } from '@services/storage/session-storage.service';
import { ErrorCodes } from '@models/error/error-codes.enum';

@Component({
  selector: 'app-rename',
  templateUrl: './rename.component.html',
  styleUrls: ['./rename.component.scss']
})
export class RenameComponent implements OnInit {

  private static readonly AUTHORIZED_CHARS = 'A-Za-z0-9àâäéèêëïîôöùûüÿç';

  public signatories: boolean = null;
  public newNamePdf: string;
  public submitted = false;

  private atLeastOneCharRegex: RegExp;
  private afterLastCharRegex: RegExp;
  private nonAuthorizedCharRegex: RegExp;


  @Input() public file: File;
  @Input() public infosDocument: DocumentDto;
  @Input() public isAlreadyUploded: boolean;
  @Output() cleanPdf: EventEmitter<File> = new EventEmitter<File>();

  constructor(private router: Router, private toastNotificationService: ToastNotificationService,
              private translateService: I18nDomainService, private uploadPdfService: UploadPdfService,
              private sessionStorageService: SessionStorageService) {
    this.atLeastOneCharRegex = new RegExp('^(?=.*[' + RenameComponent.AUTHORIZED_CHARS + '])');
    this.afterLastCharRegex = new RegExp('[^' + RenameComponent.AUTHORIZED_CHARS + '_\-]*$');
    this.nonAuthorizedCharRegex = new RegExp('[^' + RenameComponent.AUTHORIZED_CHARS + '_\-]', 'g');
  }

  public ngOnInit(): void {
    this.newNamePdf = this.file ? this.file.name : this.infosDocument.filename;
    this.formatFileName();
  }

  get pdfNameValidator(): boolean {
    return this.pdfNameNotEmpty && this.pdfNameAtLeastOneChar;
  }

  get pdfNameLengthValidator(): boolean {
    return this.newNamePdf.length <= 50;
  }

  get pdfNameNotEmpty(): boolean {
    return StringUtils.isNotBlank(this.newNamePdf);
  }

  get pdfNameAtLeastOneChar(): boolean {
    return this.atLeastOneCharRegex.exec(this.newNamePdf) !== null;
  }

  public formatFileName(): void {
    this.newNamePdf = this.newNamePdf.trim();
    // remove .pdf ignore case
    this.newNamePdf = this.newNamePdf.replace(/\.pdf$/i, '');
    // remove everything after the last authorized char
    this.newNamePdf = this.newNamePdf.replace(this.afterLastCharRegex, '');
    // replace space and . by _ if not end of line
    this.newNamePdf = this.newNamePdf.replace(/[ \.](?!$)/g, '_');
    // remove non-listed characters
    this.newNamePdf = this.newNamePdf.replace(this.nonAuthorizedCharRegex, '');
  }

  public goToPreviewPdf(uploadForm: NgForm): void {
    this.submitted = true;
    this.formatFileName();
    if (uploadForm.valid && this.signatories !== null && this.pdfNameValidator) {
      if (this.isAlreadyUploded) {
        this.uploadPdfService.renamePdf(this.infosDocument.id, this.newNamePdf, this.signatories)
          .then((result: string) => {
            this.router.navigate(['signature/preview-pdf', this.infosDocument.id],
              { queryParams: { multiSignatories: this.signatories } });
          })
          .catch((err) => {
            this.toastNotificationService.error('upload-pdf.upload.error.upload-pdf');
          });
      } else {
        this.uploadPdfService.uploadPdf(this.file, this.newNamePdf, this.signatories)
          .then((result: string) => {
            const idDocument = result;
            this.router.navigate(['signature/preview-pdf', idDocument],
              { queryParams: { multiSignatories: this.signatories } });
          })
          .catch((err) => {
            switch (err.error.code) {

              case ErrorCodes.BUSI_SIGN_02:
                this.toastNotificationService.error('upload-pdf.upload.error.protection-password');
                break;
              case ErrorCodes.BUSI_SIGN_03:
                this.toastNotificationService.error('upload-pdf.upload.error.maxsize-pdf');
                break;
              case ErrorCodes.NOT_PDF:
                this.toastNotificationService.error('upload-pdf.upload.error.only-pdf');
                break;
              default:
                this.toastNotificationService.error('upload-pdf.upload.error.upload-pdf');
            }
          });
      }
    }
  }

  public goBack(): void {
    this.file = null;
    this.sessionStorageService.remove('idDocument');
    this.cleanPdf.emit();
  }

}
