import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {StringUtils} from '@ingroupe/common-utils';
import {ConfigurationService} from '@services/configuration/configuration.service';
import {DocumentInfoService} from '@services/document/document-info.service';
import {Subscription} from 'rxjs';
import {DocumentDto} from '@models/document/document.model';
import {ErrorCodes} from '@models/error/error-codes.enum';

@Component({
  selector: 'app-access-statement',
  templateUrl: './validation-code.component.html',
  styleUrls: ['./validation-code.component.scss']
})
export class ValidationCodeComponent implements OnInit {
  /**
   * The success access code check redirect route
   */
  private static readonly TARGET_ROUTE: string = 'guest/previewPdf';
  /**
   * Indicate if the form has been submitted
   */
  submitted: boolean;
  /**
   * The typed document access code
   */
  accessCode: string;
  /**
   * Indicate that invalid code
   */
  isInvalidCode: boolean;
  /**
   * the document data
   */
  document: DocumentDto;
  /**
   * Access code max lenght
   */
  accessCodeMaxLength: number;

  /**
   * indicates that it had too many code attempts
   */
  tooManyCodeAttempts: boolean;

  /**
   * indicates that user already signed the document
   */
  isDocumentSigned: boolean;

  /**
   * The statement id passed into url parameters
   */
  private statementId: string;
  /**
   * The route data subsciption
   */
  private dataSubscription: Subscription;


  constructor(private configService: ConfigurationService, private route: ActivatedRoute,
              private router: Router, private documentInfoService: DocumentInfoService) {
  }

  /**
   * On init component listener
   */
  public ngOnInit(): void {
    this.statementId = this.route.snapshot.paramMap.get('id');
    this.accessCodeMaxLength = this.configService.getConfig('documentDownload.accessCodeLength') || 4;
    this.tooManyCodeAttempts = false;

    this.dataSubscription = this.route.data.subscribe(data => {
      this.document = data.info.document;
    });
  }

  /**
   * Check the given access code
   */
  public verifyAccessCode(): void {
    this.submitted = true;
    if (StringUtils.isNotBlank(this.accessCode)) {
      this.documentInfoService.verifyAccessCode(this.statementId, {accessCode: this.accessCode})
        .then(() => {
          this.router.navigate(['/signature/guest/preview-pdf', this.statementId]);
        })
        .catch((reason) => {
          switch (reason.error.code) {
            case ErrorCodes.INVALID_ACCES_CODE:
              this.isInvalidCode = true;
              break;
            case ErrorCodes.TOO_MANY_CODE_ATTEMPTS:
              this.tooManyCodeAttempts = true;
              break;
            case ErrorCodes.DOCUMENT_ALREADY_SIGNED:
              this.isDocumentSigned = true;
              break;
            default:
              console.log(reason.error.message);
          }
        });
    }
  }
}
