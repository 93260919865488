import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { CoreModule } from '@core/core.module';
import { UserService } from '@services/user/user.service';
import { GcuService } from '@services/gcu/gcu.service';
import { GcuComponent } from '@pages/gcu/gcu.component';
import { ObjectUtils, StringUtils } from '@ingroupe/common-utils';

/**
 * The authentication guard to route home page if the user is not authenticated
 * or to gcu page if gcu has not been accepted
 */
@Injectable({
  providedIn: CoreModule
})
export class AuthGuard implements CanActivate {
  /**
   * Constructor
   *
   * @param userService : the user service to check if the user is authenticated
   * @param gcuService : the gcu services to check if the user has accepted gcu
   * @param router : the router to route to incompatible navigator page
   */
  constructor(private userService: UserService, private gcuService: GcuService, private router: Router) {

  }

  /**|
   * Guard route activation check
   */
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | UrlTree {
    const params: URLSearchParams = new URLSearchParams(window.location.search);
    const currentLang: string = params.get('lang');

    if (!this.userService.isAuthenticated()) {
      return this.router.parseUrl('/home');
    } else if (next.component !== GcuComponent && !this.gcuService.isAccepted()) {
      const matches: RegExpMatchArray = window.location.pathname.match(/\/guest\/([a-zA-Z0-9-]+)/);
      if (ObjectUtils.isNotNullOrUndefined(matches) && StringUtils.isNotBlank(matches[1])) {
        return this.router.navigate(['/gcu'], { queryParams: { originUri: window.location.pathname } });
      }
      return this.router.navigate(['/gcu'], { queryParams: { lang: currentLang } });
    }

    return Promise.resolve(true);
  }
}
