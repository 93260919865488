import { Injectable } from '@angular/core';
import { CoreModule } from '@core/core.module';
import { ObjectUtils, StringUtils } from '@ingroupe/common-utils';
import { IngroupeTranslateService } from '@ingroupe/translate';
import { ThemeService } from '@services/theme/theme.service';

/**
 * The i18n translate service with domain management
 */
@Injectable(
    {
        providedIn: CoreModule
    }
)
export class I18nDomainService {
    /**
     * The default domain to resolve key
     */
    private defaultDomain: string;

    /**
     * The current domain to resolve key
     */
    private currentDomain: string;

    constructor(
        private themeService: ThemeService,
        private translateService: IngroupeTranslateService
    ) {
        this.defaultDomain = this.themeService.getDefaultTheme().name;
        this.currentDomain = this.themeService.getActiveTheme().name;
    }

    /**
     * Translate key if is not blank
     *
     * @param key : the key to translate
     * @param interpolateParams : the message parameters
     * @return translated message or key if blank
     */
    public resolveMessage(key: string, interpolateParams?: object): string {
        let keyToResolve = `${this.currentDomain}.${key}`;
        let value: string = this.translateService.resolveMessage(keyToResolve, interpolateParams) as string;
        if (ObjectUtils.isNullOrUndefined(value) || StringUtils.equals(value, keyToResolve)) {
            keyToResolve = `${this.defaultDomain}.${key}`;
            value = this.translateService.resolveMessage(keyToResolve, interpolateParams) as string;
            if (ObjectUtils.isNullOrUndefined(value) || StringUtils.equals(value, keyToResolve)) {
                value = this.translateService.resolveMessage(key, interpolateParams) as string || key;
            }
        }

        return value;
    }
}
