<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item active" aria-current="page"><i class="fas fa-home mr-1"></i>{{'breadcrumb.dashboard' | themeTranslate | capitalizeFirst}}</li>
  </ol>
</nav>

<h2 class="mt-3">{{'dashboard.title.page' | themeTranslate | capitalizeFirst}}</h2>
<p class="text-justify">
  <span innerHTML="{{'dashboard.notice.line1' | themeTranslate }}"></span>
  <span class="d-block my-2" innerHTML="{{'dashboard.notice.line2' | themeTranslate }}"></span>
</p>

<div class="d-flex flex-column flex-sm-row my-3 my-sm-4">
  <button (click)="signDocument()"
    class="btn btn-primary">{{'dashboard.buttons.haveDocumentSigned' | themeTranslate}}</button>
  <button (click)="navigateToMyDocuments()" class="btn btn-outline-primary ml-0 ml-sm-3 mt-3 mt-sm-0">
    {{'dashboard.buttons.consultMyDoc' | themeTranslate}}</button>
</div>

<h3 class="mt-3 mt-md-5">{{'dashboard.title.activity' | themeTranslate | capitalizeFirst}}</h3>

<div *ngIf="showNotificationBanner" class="d-flex align-items-center banner banner-info mb-2">
  <div class="line-left warning mr-3"></div>
  <ng-container>
    <i class="fas fa-exclamation-circle banner-icon mr-3"></i>
    <a class="bold" routerLink="/notifications">{{'dashboard.notifications.link' | themeTranslate | capitalizeFirst}}</a>
    <span class="ml-1">{{'dashboard.notifications.text' | themeTranslate}}</span>
  </ng-container>
</div>

<div class="activities" *ngIf="countByStatuses.length">
  <ng-container *ngFor="let statusCount of countByStatuses; let isFirst = first; let isLast = last;">
    <div class="d-flex align-items-center banner banner-{{statusCount.alertType.name}}"
      [ngClass]="{'my-2': !isFirst && !isLast, 'mb-2': isFirst}">
      <div class="line-left mr-3" [ngClass]="statusCount.alertType.name === 'warning' ? 'warning' : 'info'"></div>
      <i class="banner-icon mr-3" [class]="statusCount.icon" *ngIf="statusCount.icon"></i>
      <div class="d-flex" [ngClass]="{'flex-row': !statusCount.linkFirst, 'flex-row-reverse': statusCount.linkFirst}">
        <span
          [translateParams]="{count: statusCount.count}" themeTranslate>{{'dashboard.activity.' + statusCount.status + '.text.' + statusCount.pluralMessageType}}</span>
        <a class="bold" [ngClass]="{'ml-1': !statusCount.linkFirst, 'mr-1': statusCount.linkFirst}" routerLink="/my-documents" [queryParams]="{statuses: statusCount.status}"
          [translateParams]="{count: statusCount.count}" themeTranslate>{{'dashboard.activity.' + statusCount.status + '.link.' + statusCount.pluralMessageType}}</a>
      </div>
    </div>
  </ng-container>
</div>
