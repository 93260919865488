<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li><a routerLink="signature/dashboard"><i class="fas fa-home mr-1"></i></a></li>
    <li class="breadcrumb-item">
      <a routerLink="signature/dashboard">{{ 'breadcrumb.dashboard' | themeTranslate | capitalizeFirst }}</a>
    </li>
    <li class="breadcrumb-item">
      <a routerLink="/my-documents">{{'breadcrumb.documents-list' | themeTranslate | capitalizeFirst}}</a></li>
    <li class="breadcrumb-item active" aria-current="page">{{sharedDocument.filename | capitalizeFirst}}</li>
  </ol>
</nav>
<ng-container *ngIf="sharedDocument">
  <h2 class="mt-4" *ngIf="sharedDocument.filename">{{sharedDocument.filename | capitalizeFirst}}</h2>
  <div class="d-flex flex-column flex-md-row align-items-start">
    <h6 class="badge" *ngIf="sharedDocument.status" [class]="formatBadge(sharedDocument.status)">
      {{'document-detail.tag-states.' + sharedDocument.status | themeTranslate | capitalizeFirst}}
    </h6>
    <h6 class="ml-md-3 pt-1" *ngIf="ctx.expireDateInfo && !ctx.canceled">
      <span *ngIf="sharedDocument.expiredDate">
        {{'document-detail.expire-info.' + ctx.expireDateInfo | themeTranslate | capitalizeFirst}}
        {{sharedDocument.expiredDate | date:'dd/MM/yyyy'}}</span>
    </h6>
  </div>
  <div class="row mt-4" *ngIf="ctx.displaySharedInfo">
    <!-- shared informations block -->
    <div class="col-md separation-line">
      <h3>{{'document-detail.signatories' | themeTranslate | capitalizeFirst}}</h3>

      <div class="bold">{{'document-detail.number-of-signatories' | themeTranslate | capitalizeFirst}}</div>
      <div class="mt-2" themeTranslate [translateParams]="{max: maximumSignatories}">document-detail.maximum</div>

      <div class="d-flex flex-column-reverse flex-md-row my-3">
        <form [formGroup]="signatoriesForm">
          <div class="input-numeric-spinner">
            <div class="form-group">
              <button class="btn btn-primary minus" (click)="setExpectedSignatoriesCount(-1)"></button>
              <input formControlName="expectedSignatureCount" id="expectedSignatureCount" type="text" size="1"
                     maxlength="1" onlyNumber lockCopyPaste/>
              <button class="btn btn-primary plus" (click)="setExpectedSignatoriesCount(1)"></button>
              <button class="btn btn-link ml-3"
                      *ngIf="controls.expectedSignatureCount.value !== sharedDocument.expectedSignatureCount"
                      (click)="updateSignatoriesNumber()">
                {{'document-detail.btn.save-signatories-number' | themeTranslate | capitalizeFirst}}</button>
              <div class="text-info invalid mt-2" *ngIf="controls.expectedSignatureCount.invalid" onlyNumber
                   themeTranslate [translateParams]="{min: this.minSignatoriesExpected() , max: 9}">
                document-detail.error.invalid-expected-signatories-count
              </div>
            </div>
          </div>
        </form>
      </div>

      <div class="d-flex flex-column flex-wrap signatories-container">
        <div *ngFor="let signatory of sharedDocument.signatories">
          <i class="far fa-check-circle mr-2"></i>
          <span>{{signatory.name}}</span>
          <span class="px-1" *ngIf="signatory.isConnectedUser">{{'document-detail.you' | themeTranslate}}</span>
        </div>
      </div>
    </div>

    <div class="col-md mt-3 mt-md-0">
      <h2>{{'document-detail.info-to-signatories.title' | themeTranslate | capitalizeFirst}}</h2>
      <p>{{'document-detail.info-to-signatories.notice' | themeTranslate | capitalizeFirst}}</p>
      <p class="bold">{{'document-detail.info-to-signatories.url-to-transmit' | themeTranslate | capitalizeFirst}}</p>
      <span>{{sharedDocument.accessUrl}}</span>
      <div class="mt-1">
        <a class="link" (click)="copyUrl()"> {{'share-document.buttons.copy' | themeTranslate | capitalizeFirst}}</a>
        <span class="mx-2">|</span>
        <a [href]="sharedMail">{{'share-document.step2.mail-link' | themeTranslate | capitalizeFirst}}</a>
      </div>
      <p class="mt-3 bold">{{'document-detail.info-to-signatories.code-to-transmit' | themeTranslate | capitalizeFirst}}
      </p>
      <span class="accesscode p-2 bold">{{sharedDocument.accessCode}}</span>
      <a class="ml-2" (click)="copyCode()"> {{'share-document.buttons.copy' | themeTranslate | capitalizeFirst}}</a>
    </div>
  </div>

  <div class="mt-3" *ngIf="!ctx.displaySharedInfo && sharedDocument.signatories && sharedDocument.signatories.length">
    <!-- signatories list -->
    <div *ngIf="!sharedDocument.isOwner" class="my-3">{{'document-detail.sent-by' | themeTranslate | capitalizeFirst}}
      <span class="bold ml-2">{{sharedDocument.commonName}}</span></div>
    <h3>{{'document-detail.signatories' | themeTranslate | capitalizeFirst}}</h3>
    <div class="d-flex flex-column flex-wrap signatories-container mt-3">
      <div *ngFor="let signatory of sharedDocument.signatories">
        <i class="far fa-check-circle mr-2"></i>
        <span>{{signatory.name}}</span>
        <span class="px-1" *ngIf="signatory.isOwner && sharedDocument.isOwner && numberOfSignatures() > 1">{{'document-detail.sender' | themeTranslate}}</span>
        <span class="px-1" *ngIf="signatory.isConnectedUser && !sharedDocument.isOwner">{{'document-detail.you' | themeTranslate}}</span>
      </div>
    </div>
  </div>

  <div *ngIf="!ctx.canceled && !ctx.unsigned" class="d-flex flex-column flex-sm-row justify-content-md-start align-items-sm-baseline mt-5 mb-3">
    <button class="btn btn-primary mr-md-3 mb-2" *ngIf="!ctx.isSignButton" fileSaver [method]="'GET'"
            [url]="buildUrlToDownloadDoc()">
      {{'document-detail.btn.download.' + (sharedDocument.status === documentStatus.ALL_SIGNED ? 'signed' : 'not-signed') | themeTranslate}}
    </button>

    <button class="btn btn-primary mr-md-3 mb-2" *ngIf="ctx.isSignButton" (click)="signDoc()">
      {{'document-detail.btn.sign' | themeTranslate}}</button>

    <button *ngIf="ctx.closeable" (click)="closeDoc(false)" class="btn btn-outline-primary">
      {{'document-detail.btn.close-doc' | themeTranslate}}
    </button>

    <button *ngIf="ctx.cancellable" (click)="openCancelConfirmationModal()" class="btn btn-outline-danger ml-md-3 mt-2">
      {{'document-detail.btn.cancel' | themeTranslate}}
    </button>
  </div>
</ng-container>

