import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { CoreModule } from '@core/core.module';
import { StatusFilter } from '@models/document/status-filter.enum';
import { DocumentInfoService } from '@services/document/document-info.service';

@Injectable({ providedIn: CoreModule })
export class DashboardResolver implements Resolve<Map<StatusFilter, number>>{
  private static readonly COUNT_STATUSES: Array<StatusFilter> =
    [StatusFilter.TO_SIGNED, StatusFilter.SOON_EXPIRED, StatusFilter.ALL_SIGNED];

  constructor(private documentInfoService: DocumentInfoService) {
  }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Promise<Map<StatusFilter, number>> {
    try {
      return await this.documentInfoService.getCountByStatus(DashboardResolver.COUNT_STATUSES);
    } catch (e) {
      return this.handleError(route, e);
    }
  }

  private handleError(route: ActivatedRouteSnapshot, errorResponse: HttpErrorResponse): Promise<Map<StatusFilter, number>> {
    return Promise.resolve(new Map<StatusFilter, number>());
  }
}
