export enum SignatureError {
    CANNOT_BE_SIGNED = 'CANNOT_BE_SIGNED',
    NO_CARD = 'NO_CARD',
    NO_CERTIFICATE = 'NO_CERTIFICATE',
    NO_SIGN_CERTIFICATE = 'NO_CERTIFICATE',
    PIN_LOCKED = 'PIN_LOCKED',
    LAST_TRY = 'LAST_TRY',
    INVALID_PIN = 'INVALID_PIN',
    CHECK_IDENTITY_FAILED = 'CHECK_IDENTITY_FAILED',
    CARD_INVALID_CERTIFICATE = 'CARD_INVALID_CERTIFICATE',
    CARD_SIGNATURE_FAILED = 'CARD_SIGNATURE_FAILED',
    CARD_REVOKED_CERTIFICATE = 'CARD_REVOKED_CERTIFICATE'
}
