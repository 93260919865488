import {Component, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {UploadPdfService} from '@services/document/upload-pdf.service';
import {I18nDomainService} from '@services/i18n/i18n-domain.service';
import {ToastNotificationService} from '@services/toast-notification/toast-notification.service';
import {DocumentConverterUtils} from '@utils/document-converter.utils';
import {SignatureService} from '@services/document/signature.service';
import {UserService} from '@services/user/user.service';
import {AuthMode} from '@models/user/mode.enum';
import {SessionStorageService} from '@services/storage/session-storage.service';
import {ModalConfirmComponent} from '@components/modal-confirm/modal-confirm.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {SignatureError} from '@models/signature/signature-error.enum';
import {IngroupeTranslateService} from '@ingroupe/translate';

@Component({
  selector: 'app-preview-before-sign',
  templateUrl: './preview-before-sign.component.html',
  styleUrls: ['./preview-before-sign.component.scss']
})
export class PreviewBeforeSignComponent implements OnInit {

  public routeDataSubscription: Subscription;
  public isMultiSignatories: string;
  public idDocument: string;
  public base64: string;

  constructor(
    private route: ActivatedRoute, private uploadPdfService: UploadPdfService,
    private translateService: I18nDomainService, private toastNotificationService: ToastNotificationService,
    private router: Router, private signatureService: SignatureService, private userService: UserService,
    private sessionStorageService: SessionStorageService, private modalService: NgbModal,
    private ingroupeIntranslateService: IngroupeTranslateService
  ) {}

  /**
   * On init component listener
   */
  ngOnInit(): void {
    this.routeDataSubscription = this.route.queryParams.subscribe(params => {
      this.isMultiSignatories = params.multiSignatories;
    });
    this.routeDataSubscription = this.route.params.subscribe(params => {
      this.idDocument = params.id;
      this.sessionStorageService.save('idDocument', this.idDocument);
      this.documentUnsigned(this.idDocument);
    });
  }

  /**
   * get unsigned document
   *
   * @param id id document
   */
  public documentUnsigned(id: string): void {
    this.uploadPdfService.documentUnsigned(id)
      .then((response: ArrayBuffer) => {
        this.base64 = DocumentConverterUtils.fromByteToBase64(response);
      })
      .catch(() => {
        this.toastNotificationService.error(this.translateService.resolveMessage('upload-pdf.upload.error.upload-pdf'));
      });
  }

  /**
   * sign document, go to qualified signature page if card authentication otherwise
   */
  public signDocument(): void {
    if (this.userService.getAuthMode() === AuthMode.MOBILE) {
      this.doAdvancedSignature();
    } else {
      this.goToQualifiedSignaturePage();
    }
  }

  /**
   * go to qualified signature page
   */
  private goToQualifiedSignaturePage(): void {
    this.sessionStorageService.remove('idDocument');
    this.router.navigate(['signature', this.idDocument], {queryParams: {multiSignatories: this.isMultiSignatories}});
  }

  /**
   * sign document in advance level
   */
  private doAdvancedSignature(): void {
    const lang = this.ingroupeIntranslateService.getCurrentLang();
    this.signatureService.doAdvancedSignature(this.idDocument, lang).then(() => {
      if (this.isMultiSignatories === 'true') {
        this.router.navigate(['signature/share-document', this.idDocument]);
      } else {
        this.router.navigate(['signature/confirmation', this.idDocument]);
      }
    }).catch(error => {
      if (error === SignatureError.CANNOT_BE_SIGNED) {
      this.openLimitSignatoriesDialog();
      } else {
        this.toastNotificationService.error('signature.error.unable-to-sign-document');
      }
    });
  }

  /**
   * go to back step
   */
  public goBack(): void {
    this.router.navigate(['signature/upload-pdf', this.idDocument]);
  }

  public goToUpload(): void {
    this.sessionStorageService.remove('idDocument');
    this.router.navigate(['signature/upload-pdf']);
  }

  /**
   * open limit Signatories dialog
   */
  private openLimitSignatoriesDialog(): void {
    const modalRef = this.modalService.open(ModalConfirmComponent, { centered: true, size: 'md' });

    const newMessageLine1 = this.translateService.resolveMessage('guest.preview-document.modal.number-of-signatories-exceeded.line-1');
    const newMessageLine2 = this.translateService.resolveMessage('guest.preview-document.modal.number-of-signatories-exceeded.line-2');

    // eslint-disable-next-line
    modalRef.componentInstance.title = this.translateService.resolveMessage('guest.preview-document.modal.number-of-signatories-exceeded.title');
    modalRef.componentInstance.message = `${newMessageLine1}<br />${newMessageLine2}`;
  }
}
