import {Directive, ElementRef, ChangeDetectorRef, Inject} from '@angular/core';
import { Theme } from '@models/theme/theme.model';
import { ObjectUtils, StringUtils } from '@ingroupe/common-utils';
import { TranslateDirective, TranslateService } from '@ngx-translate/core';
import { ThemeService } from '@services/theme/theme.service';
import { isObservable, Subscription } from 'rxjs';

/**
 * The theme translate directive
 * It allow to translate a key in function of the current selected theme
 */
@Directive({
    selector: '[themeTranslate]'
})
export class ThemeTranslateDirective extends TranslateDirective {
    private defaultThemeName: string;
    lastParams: any;
    currentParams: any;


    constructor(
      @Inject(TranslateService) private i18nService, private host: ElementRef,
        private ref: ChangeDetectorRef, private themeService: ThemeService
    ) {
        super(i18nService, host, ref);

        if (ObjectUtils.isNullOrUndefined(this.defaultThemeName)) {
            this.defaultThemeName = this.getKeyPrefix(this.themeService.getDefaultTheme());
        }
    }

    /**
     * Get the key prefix in function of theme
     *
     * @param theme : the theme
     */
    private getKeyPrefix(theme: Theme): string {
        let themeName = '';
        if (ObjectUtils.isNotNullOrUndefined(theme)) {
            themeName = theme.name;
            if (StringUtils.isNotBlank(themeName)) {
                themeName += '.';
            }
        }

        return themeName;
    }

    /**
     * Override update value from translate directive
     *
     * @param key the key to translate
     * @param node the html node
     * @param translations : the translations
     */
    updateValue(key: string, node: any, translations: any): void {
        let themeKeyPrefix: string = this.getKeyPrefix(this.themeService.getActiveTheme());
        if (StringUtils.isBlank(themeKeyPrefix)) {
            themeKeyPrefix = this.defaultThemeName;
        }

        const themeKey = `${themeKeyPrefix}${key}`;

        const defaultKeysToTranslate: Array<string> = new Array<string>();
        defaultKeysToTranslate.push(key);
        if (!StringUtils.equals(themeKeyPrefix, this.defaultThemeName)) {
            defaultKeysToTranslate.push(`${this.defaultThemeName}${key}`);
        }

        this.doTranslate(themeKey, defaultKeysToTranslate, node, translations);
    }

    /**
     * Do the key translation
     *
     * @param key : the key to tranlate
     * @param defaultKeysTranslate : the default key list if the key is not resolved
     * @param node : the directive html node
     * @param translations : the translation keys cache
     */
    private doTranslate(key: string, defaultKeysTranslate: Array<string>, node: any, translations: any): void {
        if (node.lastKey === key && this.lastParams === this.currentParams) {
            return;
        }

        this.lastParams = this.currentParams;

        let subscription: Subscription;
        const onTranslation = (res: string) => {
            if (!StringUtils.equalsIgnoreCase(res, key)) {
                node.lastKey = key;
                this.setContent(node, res);
                this.ref.markForCheck();
            } else if (defaultKeysTranslate.length > 0) {
                const defaultKey: string = defaultKeysTranslate.pop();
                this.doTranslate(defaultKey, defaultKeysTranslate, node, translations);
            }

            if (ObjectUtils.isNotNullOrUndefined(subscription)) {
                subscription.unsubscribe();
            }
        };

        if (ObjectUtils.isNotNullOrUndefined(translations)) {
            const res = this.i18nService.getParsedResult(translations, key, this.currentParams);
            if (isObservable(res)) {
                subscription = res.subscribe(onTranslation);
            } else {
                onTranslation(res);
            }
        } else {
            subscription = this.i18nService.get(key, this.currentParams).subscribe(onTranslation);
        }
    }
}
