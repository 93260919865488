import {Component, OnDestroy, OnInit} from '@angular/core';
import {environment} from '@env/environment.prod';
import {Theme} from '@models/theme/theme.model';
import {ThemeService} from '@services/theme/theme.service';
import {AutoUnsubscribe} from 'ngx-auto-unsubscribe';
import {Subscription} from 'rxjs';
import {UserService} from '@services/user/user.service';
import {GcuService} from '@services/gcu/gcu.service';

@AutoUnsubscribe()
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {

  /**
   * Indicate if the user is authenticated
   */
  isAuthenticated: boolean;

  /**
   * The client logo path to display
   */
  clientLogo: string;

  /**
   * The manufacturer logo logo path to display
   */
  manufacturerLogo: string;

  /**
   * The app version
   */
  appVersion: string;


  /**
   * Indicate the state of acceptance GCU
   */
  gcuAccepted: boolean;

  /**
   * The state of acceptance GCU listener
   */
  gcuStateSubscription: Subscription;

  /**
   * Current year for copyright
   */
  year = new Date().getFullYear();

  /**
   * The on theme changed listener
   */
  private onThemeChanged: Subscription;



  /**
   * Constructor
   */
  public constructor(private themeService: ThemeService, private userService: UserService, private gcuService: GcuService) {
    this.onThemeChanged = this.themeService.onThemeChanged.subscribe((theme: Theme) => {
      this.clientLogo = theme.config.footer.clientLogo;
      this.manufacturerLogo = theme.config.footer.manufacturerLogo;
    });

    const activeTheme: Theme = themeService.getActiveTheme();
    this.clientLogo = activeTheme.config.footer.clientLogo;
    this.manufacturerLogo = activeTheme.config.footer.manufacturerLogo;
  }

  /**
   * On init component listener
   */
  public ngOnInit(): void {
    this.isAuthenticated = this.userService.isAuthenticated();
    this.appVersion = environment.version;
    this.gcuStateSubscription = this.gcuService.gcuState.subscribe(state => this.gcuAccepted = state);
  }

  /**
   * On destroy page listener
   */
  public ngOnDestroy(): void {

  }
}
