import {HTTP_INTERCEPTORS, HttpClient} from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HttpSessionInterceptor } from '@core/interceptors/http.interceptor';
import { LayoutComponent } from '@core/layout/layout.component';
import { getI18nConfiguration, initialize } from '@core/loader/application.loader';
import { CardReaderInitializerService, CardReaderService } from '@ingroupe/card-reader';
import {INGROUPE_TRANSLATE_CONFIG, IngroupeTranslateConfiguration} from '@ingroupe/translate';
import { PagesModule } from '@pages/pages.module';
import { ConfigurationService } from '@services/configuration/configuration.service';
import { GcuService } from '@services/gcu/gcu.service';
import { ThemeService } from '@services/theme/theme.service';
import { UserService } from '@services/user/user.service';
import { SignatureService } from '@services/document/signature.service';
import { MatomoModule } from 'ngx-matomo';
import {TranslateModule, TranslateLoader, TranslateService} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function httpLoaderFactory(http: HttpClient,
                                  configurationService: ConfigurationService, themeService: ThemeService): TranslateHttpLoader {
  const config: IngroupeTranslateConfiguration = configurationService.getConfig('translate');
  config.domains = themeService.getAllRegisteredTheme();
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}


@NgModule({
  imports: [
    PagesModule,
    MatomoModule,
    TranslateModule.forRoot({
      defaultLanguage: 'fr',
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient, ConfigurationService, ThemeService]
      }
    })
  ],
  providers: [
    ConfigurationService,
    ThemeService,
    UserService,
    GcuService,
    CardReaderInitializerService,
    CardReaderService,
    SignatureService,
    TranslateService,
    {
      provide: APP_INITIALIZER,
      useFactory: initialize,
      deps: [ConfigurationService, ThemeService, UserService, GcuService, CardReaderInitializerService, SignatureService],
      multi: true
    },
    { provide: INGROUPE_TRANSLATE_CONFIG, useFactory: getI18nConfiguration, deps: [ConfigurationService, ThemeService] },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpSessionInterceptor,
      multi: true
    }
  ],
  bootstrap: [LayoutComponent],
  exports: [
    TranslateModule,
  ]
})
export class AppModule {
}
