<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a routerLink="/signature/dashboard"><i
      class="fas fa-home mr-1"></i>{{'breadcrumb.dashboard' | themeTranslate | capitalizeFirst}}</a></li>
    <li class="breadcrumb-item"><a (click)="goToUpload()">{{'breadcrumb.upload' | themeTranslate | capitalizeFirst}}</a>
    </li>
    <li class="breadcrumb-item"><a (click)="goBack()">{{'breadcrumb.naming' | themeTranslate | capitalizeFirst}}</a>
    </li>
    <li class="breadcrumb-item active"
        aria-current="page">{{'breadcrumb.preview' | themeTranslate | capitalizeFirst}}</li>
  </ol>
</nav>

<h2 class="mt-3">{{'preview-pdf.title' | themeTranslate | capitalizeFirst}}</h2>
<div class="text-justify">
  <div>{{'preview-pdf.notice.line1' | themeTranslate | capitalizeFirst}}</div>
  <div>{{'preview-pdf.notice.line2.text' | themeTranslate | capitalizeFirst}}</div>
</div>

<div class="col p-0 h-auto my-3">
  <ngx-extended-pdf-viewer *ngIf="base64" [showUnverifiedSignatures]="false" [useBrowserLocale]="true"
                           [mobileFriendlyZoom]="'100%'"
                           [base64Src]="base64" [height]="'60vh'" [showSecondaryToolbarButton]="false"
                           [showBookmarkButton]="false"
                           [showPrintButton]="false" [showDownloadButton]="false" [showOpenFileButton]="false"
                           [contextMenuAllowed]="false" [ignoreKeyboard]="true">
  </ngx-extended-pdf-viewer>
</div>

<div class="actions">
  <button (click)="goBack()"
          class="btn btn-outline-primary">{{'preview-pdf.button.return' | themeTranslate | capitalizeFirst}}</button>
  <button (click)="signDocument()"
          class="btn btn-primary ml-md-3">{{'preview-pdf.button.sign' | themeTranslate }}</button>
</div>
