import { Inject, Injectable } from '@angular/core';
import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';
import { CoreModule } from '@core/core.module';

/**
 * The session storage service
 */
@Injectable(
  {
    providedIn: CoreModule
  }
)
export class SessionStorageService {
  /**
   * Constructor
   *
   * @param storage : the storage service
   */
  constructor(@Inject(SESSION_STORAGE) private storage: StorageService) {

  }

  /**
   * Save into session storage
   *
   * @param storageKey : the storage key to save
   * @param objToStore  : the value to save
   */
  public save(storageKey: string, objToStore: any): void {
    // insert or update
    this.storage.set(storageKey, objToStore);
  }

  /**
   * Find value into session storage
   *
   * @param storageKey : the storage key to find value
   */
  public find(storageKey: string): any {
    // get from storage with key
    return this.storage.get(storageKey);
  }

  /**
   * Removes the entry that is identified by the specified key.
   */
  public remove(key: string): void {
    this.storage.remove(key);
  }

  /**
   * Clears the storage by removing all entries.
   */
  public clear(): void {
    this.storage.clear();
  }
}
