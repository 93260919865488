<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a
      routerLink="/signature/dashboard"><i class="fas fa-home mr-1"></i>{{'breadcrumb.dashboard' | themeTranslate | capitalizeFirst}}</a></li>
    <li class="breadcrumb-item" *ngIf="displayRename()"><a
      (click)="initPdf()">{{'breadcrumb.upload' | themeTranslate | capitalizeFirst}}</a></li>
    <li class="breadcrumb-item active" aria-current="page"
        *ngIf="!displayRename()">{{'breadcrumb.upload' | themeTranslate | capitalizeFirst}}</li>
    <li class="breadcrumb-item active" *ngIf="displayRename()"
        aria-current="page">{{'breadcrumb.naming' | themeTranslate | capitalizeFirst}}</li>
  </ol>
</nav>

<h2 class="mt-3 mt-md-3">
  {{ (displayRename() ? 'upload-pdf.rename.title.page' : 'upload-pdf.upload.title.page') | themeTranslate | capitalizeFirst }}</h2>

<ng-container *ngIf="!displayRename()">
  <p class="my-3">{{'upload-pdf.upload.sub-title' | themeTranslate | capitalizeFirst}}</p>
  <div class="notice w-50 row ml-0 px-2 py-4 my-4">
    <div class="col-2 col-md-1"><i class="fas fa-info-circle"></i></div>
    <div class="col-10 col-md-11">
      <p class="bold">{{'upload-pdf.notice.line1' | themeTranslate | capitalizeFirst}}</p>
      <ul>
        <li>{{'upload-pdf.notice.pdf-format' | themeTranslate}}</li>
        <li>{{'upload-pdf.notice.file-size' | themeTranslate}}</li>
        <li>{{'upload-pdf.notice.no-password-protection' | themeTranslate}}</li>
      </ul>
      <a class="bold" href="https://get.adobe.com/fr/reader/" target="_blank">{{'upload-pdf.notice.link' | themeTranslate | capitalizeFirst}}</a>
    </div>
  </div>

  <div class="row d-flex flex-row">
    <div class="col-12 h-100">
      <div class="custom-dropzone" ngx-dropzone [accept]="fileType" [maxFileSize]="maxFileSize"
           [multiple]="multipleFile" (change)="onFileSelect($event)">
        <button class="btn btn-primary"
                (click)="onFileSelect($event)">{{'upload-pdf.upload.zone-upload.button' | themeTranslate}}</button>
        <ngx-dropzone-label class="dropzone-label mb-3">{{'upload-pdf.upload.zone-upload.label' | themeTranslate}}</ngx-dropzone-label>
      </div>
    </div>
  </div>
</ng-container>

<app-rename *ngIf="displayRename()" [file]="file" [infosDocument]="infosDocument" [isAlreadyUploded]="isAlreadyUploded"
            (cleanPdf)="initPdf()"></app-rename>
