import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import { CoreModule } from '@core/core.module';
import { Email } from '@shared/models/notifications/email.model';
import { NotificationService } from '@services/notification/notification.service';

@Injectable({ providedIn: CoreModule })
export class NotificationActiveResolver implements Resolve<Email> {

  constructor(private notificationService: NotificationService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<Email> {
    return this.notificationService.notificationState();
  }
}
