import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CoreModule } from '@core/core.module';
import { LayoutService } from '@services/layout/layout.service';
import { UserService } from '@services/user/user.service';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

@Injectable({
  providedIn: CoreModule
})
export class HttpSessionInterceptor implements HttpInterceptor {
  constructor(private layoutService: LayoutService, private userService: UserService) {
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.layoutService.displayLoadingSpinner();
    return next.handle(req).pipe(
      catchError(httpError => {
        this.layoutService.hideLoadingSpinner();
        if (httpError.status === 401 && !req.url.startsWith(UserService.REALM_BASE_URI)) {
          window.location.href = this.userService.getLogoutUrl();
          return;
        }
        return throwError(httpError);
      }),
      finalize(() => this.layoutService.hideLoadingSpinner())
    );
  }
}
