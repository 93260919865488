import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {CoreModule} from '@core/core.module';
import {ArrayUtils, ObjectUtils, StringUtils} from '@ingroupe/common-utils';
import {RealmInfo} from '@models/user/realm-info.model';
import {UserInfo} from '@models/user/user-info.model';
import {ConfigurationService} from '@services/configuration/configuration.service';
import {LayoutService} from '@services/layout/layout.service';
import {catchError, tap} from 'rxjs/operators';
import {AuthMode} from '@models/user/mode.enum';

@Injectable({
    providedIn: CoreModule
})
export class UserService {
    public static readonly REALM_BASE_URI: string = '/redirect_uri';
    private static readonly LOGOUT_URI: string = `${UserService.REALM_BASE_URI}?logout={0}`;
    private static readonly USER_INFO_URI: string = `${UserService.REALM_BASE_URI}?info=json{0}`;

    /**
     * The current realm info
     */
    infos: RealmInfo;

    /**
     * Constructor
     *
     * @param httpClient : The http client instance
     * @param configService : The configuration service
     * @param layoutService : the layout service
     */
    constructor(private httpClient: HttpClient, private configService: ConfigurationService, private layoutService: LayoutService) {
    }

    /**
     * Get user infos
     *
     * @returns The user info
     */
    public getUserInfo(): UserInfo {
        return (this.infos) ? this.infos.userinfo : null;
    }

    /**
     * Get Authentication Mode
     *
     * @return the authentication mode
     */
    public getAuthMode(): AuthMode {
        return (this.infos && this.infos.id_token) ? this.infos.id_token.authMode : null;
    }

    /**
     * Get the real infos
     *
     * @param refresh Indicate to force refresh token
     * @param refreshInterval The refresh token interval
     * @returns Promise of realm infos
     */
    public getRealmInfo(refresh: boolean = false, refreshInterval: number = 0): Promise<RealmInfo> {
        if (!refresh && ObjectUtils.isNotNullOrUndefined(this.infos)) {
            return Promise.resolve(this.infos);
        }

        const userInfoUri: string = StringUtils.format(UserService.USER_INFO_URI, refresh ?
            `&access_token_refresh_interval=${refreshInterval}` : '');

        return this.httpClient.get<any>(userInfoUri).pipe(
            tap(infos => {
                this.infos = infos;
                this.layoutService.displayUserInfo(ObjectUtils.isNotNullOrUndefined(this.infos.userinfo));
            }),
            catchError((error: HttpErrorResponse): any => {
                this.layoutService.displayUserInfo(false);
                return Promise.resolve(null);
            })
        ).toPromise();
    }

    /**
     * Get the logout url
     *
     * @returns The logout url
     */
    public getLogoutUrl(): string {
        const logoutUri: string = this.configService.getEndPoint('auth.logout');
        let redirectUri: string = encodeURI(`${window.location.protocol}//${window.location.host}`);
        if (StringUtils.isNotBlank(logoutUri)) {
            redirectUri += logoutUri;
        }

        return StringUtils.format(UserService.LOGOUT_URI, redirectUri);
    }

    /**
     * Get the login url
     *
     * @param lang : the current used lang
     * @returns The login url
     */
    public getLoginUrl(lang: string): string {
        return StringUtils.format(this.configService.getEndPoint('auth.login'), lang);
    }

    /**
     * Indicate if the user is authenticated
     *
     * @returns true if he is authenticated otherwise false
     */
    public isAuthenticated(): boolean {
        return ObjectUtils.isNotNullOrUndefined(this.infos);
    }

    /**
     * Check if the user has expected role
     *
     * @param expectedRole The expected role
     * @returns true if the user has the expected role otherwise false
     */
    public hasRole(expectedRole: string): boolean {
        return this.hasRoles([expectedRole]);
    }

    /**
     * Check if the user has expected roles
     *
     * @param expectedRole The expected roles array
     * @param logicalOperator The logical operator to match roles
     * @returns true if the user has the expected role and match the given logical operator otherwise false
     */
    public hasRoles(expectedRoles: Array<string>, logicalOperator = 'OR'): boolean {
        let hasPermission = false;

        if (ArrayUtils.isEmpty(expectedRoles)) {
            return true;
        }

        if (ArrayUtils.isEmpty(this.infos.userinfo.roles)) {
            return false;
        }

        if (this.infos) {
            for (const checkRole of expectedRoles) {
                let permissionFound = null;
                if (checkRole.endsWith('*')) {
                    const searchRole = checkRole.substr(0, checkRole.length - 1);
                    permissionFound = this.infos.userinfo.roles.find(role =>
                        role.startsWith(searchRole)
                    );
                } else {
                    permissionFound = this.infos.userinfo.roles.find(
                        role =>
                            StringUtils.equalsIgnoreCase(role, checkRole)
                    );
                }

                if (permissionFound) {
                    hasPermission = true;

                    if (StringUtils.equalsIgnoreCase(logicalOperator, 'OR')) {
                        break;
                    }
                } else {
                    hasPermission = false;
                    if (StringUtils.equalsIgnoreCase(logicalOperator, 'AND')) {
                        break;
                    }
                }
            }
        }

        return hasPermission;
    }
}
