import { CardReaderInitializerService } from '@ingroupe/card-reader';
import { IngroupeTranslateConfiguration } from '@ingroupe/translate';
import { ConfigurationService } from '@services/configuration/configuration.service';
import { SignatureService } from '@services/document/signature.service';
import { GcuService } from '@services/gcu/gcu.service';
import { ThemeService } from '@services/theme/theme.service';
import { UserService } from '@services/user/user.service';

/**
 * Application initializer
 *
 * @param configurationService : the configuration service instance
 * @param themeService : the theme configuration service
 * @param userService : the user service
 * @param gcuService : the gcu service
 * @param cardReaderInitializrService: the card context initializer service
 * @param cardReaderService: the card reader service
 * @param signatureService : the document signature service
 */
export function initialize(
  configurationService: ConfigurationService,
  themeService: ThemeService,
  userService: UserService, gcuService: GcuService,
  cardReaderInitializrService: CardReaderInitializerService,
  signatureService: SignatureService
): () => Promise<void> {
  return () => new Promise(async (resolve, reject) => {
    await configurationService.loadGlobalConfiguration();
    await configurationService.loadEndPointConfiguration();
    await themeService.configureTheme();
    await userService.getRealmInfo();

    if (userService.isAuthenticated()) {
      await gcuService.initialize(getI18nConfiguration(configurationService, themeService).availableLanguages);
      try {
        await cardReaderInitializrService.initialize(configurationService.getConfig('hlogic'));
      } catch (e) {
        // failed to get card, card is locked or card context cannot be initialized then logout
        console.error('Error pending card reader context initialization then disable this authentication type...');
      }
    }
    resolve();
  });
}

/**
 * Get the i18n configuration
 *
 * @param configurationService : the configuration service instance
 * @param themeService : the theme configuration service
 * @return the i18nConfiguration
 */
export function getI18nConfiguration(
  configurationService: ConfigurationService, themeService: ThemeService
): IngroupeTranslateConfiguration {
  const config: IngroupeTranslateConfiguration = configurationService.getConfig('translate');
  config.domains = themeService.getAllRegisteredTheme();
  return config;
}
