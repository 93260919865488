import { Component, OnInit } from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'signal-document-modal',
  templateUrl: './signal-document-modal.component.html',
  styleUrls: ['./signal-document-modal.component.scss']
})
export class SignalDocumentModalComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal) {
  }

  /**
   * On init component listener
   */
  ngOnInit(): void {
  }

  public dismiss(): void {
    this.activeModal.dismiss();
  }

}
