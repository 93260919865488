import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CoreModule } from '@core/core.module';
import { ArrayUtils, MapUtils, StringUtils } from '@ingroupe/common-utils';
import { PublicDocument } from '@models/document/public-document.model';
import { StatusFilter } from '@models/document/status-filter.enum';
import { ConfigurationService } from '@services/configuration/configuration.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: CoreModule
})
export class DocumentInfoService {

  constructor(private http: HttpClient, private config: ConfigurationService) {
  }

  public publicDocumentInfo(documentId: string): Promise<PublicDocument> {
    const path = StringUtils.format(this.config.getEndPoint('api').get.publicDocumentInfo, documentId);
    return this.http.get<PublicDocument>(path).toPromise();
  }

  public verifyAccessCode(documentId: string, accessCode): Promise<any> {
    const path = this.config.getEndPoint('api').post.verifyAccessCode;
    const newPath = StringUtils.format(path, documentId);
    return this.http.post(newPath, accessCode).toPromise();
  }

  /**
   * Count documents by given statuses
   *
   * @param statuses : the document statuses to count
   */
  public getCountByStatus(statuses: Array<StatusFilter>): Promise<Map<StatusFilter, number>> {
    let path = this.config.getEndPoint('api').get.countDocumentsByStatus;
    path = StringUtils.format(path, ArrayUtils.isNotEmpty(statuses) ? statuses.join(',') : '');
    return this.http.get<any>(path)
      .pipe(map(result => MapUtils.fromObject(result) as Map<StatusFilter, number>))
      .toPromise();
  }
}
