export class DocumentConverterUtils {

  /**
   * Constructor
   */
  private constructor() {

  }

  /**
   * take a document in bytes and convert it to base 64
   *
   * @param documentByte the document to convert
   */
  public static fromByteToBase64(documentByte: ArrayBuffer): string {
    return btoa(
      new Uint8Array(documentByte)
        .reduce((data, byte) => data + String.fromCharCode(byte), '')
    );
  }
}
