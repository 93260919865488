<div class="modal-header">
  <h3 class="modal-title bold">{{ 'guest.preview-document.signal-document.title' | themeTranslate | capitalizeFirst }}</h3>
  <div class="close" aria-label="Close" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </div>
</div>
<div class="modal-body">
  <p class="text-justify">{{ 'guest.preview-document.signal-document.line-1' | themeTranslate | capitalizeFirst }} </p>
  <p class="text-justify">{{ 'guest.preview-document.signal-document.line-2' | themeTranslate | capitalizeFirst }} </p>
  <div>
    <p class="text-justify">
    <span class="bold">{{ 'guest.preview-document.signal-document.line-3-part1'| themeTranslate | capitalizeFirst }} </span>
    <span>{{ 'guest.preview-document.signal-document.line-3-part2' | themeTranslate }} </span>
    <span class="bold">{{ 'guest.preview-document.signal-document.line-3-part3' | themeTranslate }} </span>
    <span>{{ 'guest.preview-document.signal-document.line-3-part4' | themeTranslate }} </span>
    <span class="bold">{{ 'guest.preview-document.signal-document.line-3-part5' | themeTranslate }} </span>
  </div>
  <p>
</div>
