import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CoreModule } from '@core/core.module';
import { StringUtils } from '@ingroupe/common-utils';
import { ConfigurationService } from '@services/configuration/configuration.service';
import { Email } from '@models/notifications/email.model';

@Injectable({
  providedIn: CoreModule
})
export class NotificationService {
  constructor(private http: HttpClient, private config: ConfigurationService) {
  }

  /**
   * State of notification for user
   *
   */
  public notificationState(): Promise<Email> {
    const url: string = this.config.getEndPoint('api.get.notificationState');
    return this.http.get<Email>(StringUtils.format(url)).toPromise();
  }

  /**
   * Register to notification for user
   *
   * @param mail : the notification mail
   * @param lang : the current lang when saving
   */
  public register(mail: string, lang: string): Promise<any> {
    const url: string = this.config.getEndPoint('api.post.registerNotification');
    return this.http.post(StringUtils.format(url), { email: mail, lang }).toPromise();
  }

  /**
   * Unregister to notification
   *
   */
  public unregister(): Promise<any> {
    const url: string = this.config.getEndPoint('api.delete.unregisterNotification');
    return this.http.delete(StringUtils.format(url)).toPromise();
  }
}
