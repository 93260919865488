import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ArrayUtils, MapUtils, ObjectUtils, StringUtils } from '@ingroupe/common-utils';
import { StatusFilter } from '@models/document/status-filter.enum';
import { FilterType } from '@models/pageable/filter-type.model';
import { Pageable } from '@models/pageable/pageable.model';
import { SortDirection } from '@models/pageable/sort-direction.enum';
import { Sort } from '@models/pageable/sort.model';

@Component({ template: '' })
export abstract class MyDocumentsFilterHandlerComponent implements OnInit, AfterViewInit {
  private activeRoute: ActivatedRoute;
  public router: Router;
  protected initialized: boolean;
  pagination: Pageable;
  filterSort: FilterType;

  constructor(route: ActivatedRoute, router: Router) {
    this.activeRoute = route;
    this.router = router;

    this.filterSort = { filename: undefined, modifiedDate: SortDirection.DESC };

    this.pagination = new Pageable();
    this.pagination.filters.status = [];
  }

  ngOnInit(): void {
    // add url query parameters filters into pagination filters
    const paramStatusFilter: string = this.activeRoute.snapshot.queryParamMap.get('statuses');
    if (StringUtils.isNotBlank(paramStatusFilter)) {
      paramStatusFilter.split(',').forEach((status) => {
        const statusFilter: string = status.trim();
        if (statusFilter in StatusFilter) {
          this.pagination.filters.status.push(statusFilter as StatusFilter);
        }
      });
    }

    const paramSenderFilter: string = this.activeRoute.snapshot.queryParamMap.get('sender');
    if (StringUtils.isNotBlank(paramSenderFilter) && ArrayUtils.contains(['1', '2'], paramSenderFilter)) {
      this.pagination.filters.creatorCode = parseInt(paramSenderFilter, 10);
    }

    // add url query parameters sorts into pagination
    const authorizedSorts = [SortDirection.ASC, SortDirection.DESC];
    const paramSortByDate: string = this.activeRoute.snapshot.queryParamMap.get('sort-date');
    if (StringUtils.isNotBlank(paramSortByDate) && ArrayUtils.contains(authorizedSorts, paramSortByDate.toLowerCase())) {
      this.filterSort.modifiedDate = paramSortByDate.toLowerCase() as SortDirection;
      this.pagination.sorts.set('modifiedDate', { direction: this.filterSort.modifiedDate, order: -1 });
    }

    const paramSortFilename: string = this.activeRoute.snapshot.queryParamMap.get('sort-filename');
    if (StringUtils.isNotBlank(paramSortFilename) && ArrayUtils.contains(authorizedSorts, paramSortFilename.toLowerCase())) {
      this.filterSort.filename = paramSortFilename.toLowerCase() as SortDirection;
      this.pagination.sorts.set('filename', { direction: this.filterSort.filename });
    }

    // hack used to avoid first load fired by page changed event
    this.initialized = true;
    this.getDocumentsList(1);
  }

  ngAfterViewInit(): void {
    // check all filtered statuses
    (this.pagination.filters.status as Array<StatusFilter>).forEach((status) => {
      const filterField: HTMLInputElement = document.querySelector(`#filter-${status}`);
      if (ObjectUtils.isNotNullOrUndefined(filterField) && !filterField.checked) {
        filterField.checked = true;
      }
    });

    if (ObjectUtils.isNotNullOrUndefined(this.pagination.filters.creatorCode)) {
      const creatorCode = this.pagination.filters.creatorCode === 1 ? 'me' : 'other';
      const filterField: HTMLInputElement = document.querySelector(`#creatorCode-${creatorCode}`);
      if (ObjectUtils.isNotNullOrUndefined(filterField) && !filterField.checked) {
        filterField.checked = true;
      }
    }

    if (MapUtils.isNotEmpty(this.pagination.sorts)) {
      this.pagination.sorts.forEach((dir: Sort, key: string) => {
        const sortField: HTMLInputElement = document.querySelector(`#${key}-${dir.direction}`);
        if (ObjectUtils.isNotNullOrUndefined(sortField)) {
          sortField.checked = true;
        }
      });
    }
    this.router.navigate([], { relativeTo: this.activeRoute, queryParams: { statuses: null } });
  }

  /**
   * Get the document list for the given page
   *
   * @param page : the page to get
   */
  abstract getDocumentsList(page?: number): void;
}
