import { Component, HostListener, OnInit, OnDestroy } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { StringUtils } from '@ingroupe/common-utils';
import { DocumentDto } from '@models/document/document.model';
import { StatusFilter } from '@models/document/status-filter.enum';
import { UserInfo } from '@models/user/user-info.model';
import { ConfigurationService } from '@services/configuration/configuration.service';
import { DocumentService } from '@services/document/document.service';
import { I18nDomainService } from '@services/i18n/i18n-domain.service';
import { ToastNotificationService } from '@services/toast-notification/toast-notification.service';
import { UserService } from '@services/user/user.service';
import { Subscription } from 'rxjs';
import { ModalConfirmComponent } from '@components/modal-confirm/modal-confirm.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClipboardService, IClipboardResponse } from 'ngx-clipboard';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';

@AutoUnsubscribe()
@Component({
  selector: 'app-document-detail',
  templateUrl: './document-detail.component.html',
  styleUrls: ['./document-detail.component.scss']
})
export class DocumentDetailComponent implements OnInit, OnDestroy {

  /**
   * The user infos
   */
  public userInfo: UserInfo;

  /**
   * Maximum number of signatories
   */
  public maximumSignatories: number;

  /**
   * The shared document metadata
   */
  sharedDocument: DocumentDto;

  /**
   * data for building the Mail
   */
  sharedMail: string;

  /**
   * The share form
   */
  public signatoriesForm: FormGroup;

  /**
   * Direct access to form controls
   */
  public controls: {
    [key: string]: AbstractControl;
  };

  /**
   * List of status for a document
   */
  public documentStatus = StatusFilter;

  /**
   * Context initialisation
   */
  public ctx: {
    displaySharedInfo?: boolean;
    expireDateInfo?: 'TO_SIGN' | 'EXPIRED';
    isSignButton?: boolean;
    closeable?: boolean;
    cancellable?: boolean;
    canceled?: boolean;
    unsigned?: boolean;
  };

  /**
   * The route data subsciption
   */
  private routeDataSubscription: Subscription;

  /**
   * The clipboard copyResponse$ subscription
   */
  private clipboardSubscription: Subscription;

  /**
   * The current document id
   */
  private documentId: string;

  constructor(
    private config: ConfigurationService, private formBuilder: FormBuilder,
    private router: Router, private route: ActivatedRoute,
    private userService: UserService, private translateService: I18nDomainService,
    private documentService: DocumentService, private toastNotificationService: ToastNotificationService,
    private clipboardService: ClipboardService,
    private configService: ConfigurationService, private modalService: NgbModal) {
    this.ctx = {};
  }

  @HostListener('input', ['$event'])
  public handleKeyboardEvent(event): void {
    if (event.target.id === 'expectedSignatureCount' && event.data < this.minSignatoriesExpected() && StringUtils.isNotBlank(event.data)) {
      this.controls.expectedSignatureCount.reset(this.minSignatoriesExpected());
    }
  }

  ngOnInit(): void {
    this.routeDataSubscription = this.route.data.subscribe(data => {
      this.documentId = this.route.snapshot.paramMap.get('id');
      this.sharedDocument = data.sharedDocument;

      const subjectMail = this.translateService.resolveMessage('document-detail.info-to-signatories.subject-mail');
      this.sharedDocument.accessUrl = this.buildAccesUrl();
      this.sharedMail = `mailto:?subject=${subjectMail}&body=${this.sharedDocument.accessUrl}`;

      this.updateContext();
    });
    this.userInfo = this.userService.getUserInfo();


    this.signatoriesForm = this.formBuilder.group({
      expectedSignatureCount: [this.sharedDocument.expectedSignatureCount, [Validators.required, Validators.min(1), Validators.max(9)]]
    });
    this.controls = this.signatoriesForm.controls;
    this.maximumSignatories = this.config.getConfig('documentDetail.maximumSignatories');

    this.clipboardService.configure({
      cleanUpAfterCopy: true
    });

    this.clipboardSubscription = this.clipboardService.copyResponse$.subscribe((response: IClipboardResponse) => {
      if (response.isSuccess) {
        this.toastNotificationService.success('share-document.success.data-copied');
      }
    });
  }

  ngOnDestroy(): void {
  }

  /**
   * Get details of a document
   */
  public refreshDatas(): void {
    this.documentService.getDocumentMetadata(this.documentId).then(response => {
      this.sharedDocument = response;
      this.sharedDocument.accessUrl = this.buildAccesUrl();
      this.updateContext();
    });
  }

  /**
   * Add or remove signatories
   *
   * @param add : number to add
   */
  public setExpectedSignatoriesCount(add: number): void {
    let newValue = 1;
    if (StringUtils.isNotBlank(`${this.controls.expectedSignatureCount.value}`)) {
      newValue = parseInt(`${this.controls.expectedSignatureCount.value}`, 10) + add;
    }

    if (newValue >= this.minSignatoriesExpected() && newValue < 10) {
      this.controls.expectedSignatureCount.setValue(newValue);
    }
  }

  /**
   * return the number of effective signatures
   */
  public numberOfSignatures(): number {
    return this.sharedDocument.signatories.length;
  }

  /**
   * update the number of signatories
   */
  public updateSignatoriesNumber(): void {
    if (this.controls.expectedSignatureCount.value > 0) {
      this.documentService.updateExpectedSignatureCount(this.documentId, this.controls.expectedSignatureCount.value)
        .then(() => {
          this.toastNotificationService.success('document-detail.success.updateSignatoriesNumber');
          this.refreshDatas();
          if (this.numberOfSignatures() === this.controls.expectedSignatureCount.value + 1) {
            this.closeDoc(true);
          }
        })
        .catch(() => {
          this.toastNotificationService.error('document-detail.error.updateSignatoriesNumber');
        });
    }
  }

  /**
   * Download the document
   */
  public buildUrlToDownloadDoc(): string {
    return StringUtils.format(this.configService.getEndPoint('api.get.downloadDocumentSignatory'),
      this.documentId, JSON.stringify(this.sharedDocument.status === StatusFilter.ALL_SIGNED));
  }

  public signDoc(): void {
    this.router.navigate(['signature/guest/preview-pdf', this.documentId]);
  }

  /**
   *  Close the document
   */
  public closeDoc(fromUpdateSignatories: boolean): void {
    const modalRef = this.modalService.open(ModalConfirmComponent, { size: 'md', backdrop: 'static' });
    modalRef.componentInstance.title = this.translateService.resolveMessage('document-detail.modal-confirm.title');
    modalRef.componentInstance.message = this.translateService.resolveMessage(fromUpdateSignatories ?
      'document-detail.modal-confirm.message-from-update-signatories' : 'document-detail.modal-confirm.message-from-click');
    modalRef.componentInstance.btnCancel = this.translateService.resolveMessage('document-detail.modal-confirm.cancel');
    modalRef.componentInstance.btnAccept = this.translateService.resolveMessage('document-detail.modal-confirm.confirm');
    modalRef.result.then(() => {
      this.documentService.closeDocument(this.documentId)
        .then(() => {
          this.toastNotificationService.success('document-detail.success.close-document');
          this.refreshDatas();
        })
        .catch(() => {
          this.toastNotificationService.error('document-detail.error.close-document');
        });
    });
  }

  public openCancelConfirmationModal(): void {
    const modalRef = this.modalService.open(ModalConfirmComponent, { size: 'md', backdrop: 'static' });
    modalRef.componentInstance.title = this.translateService.resolveMessage('document-detail.cancel-confirmation.title');
    modalRef.componentInstance.message = this.translateService.resolveMessage('document-detail.cancel-confirmation.content');
    modalRef.componentInstance.btnCancel = this.translateService.resolveMessage('document-detail.cancel-confirmation.btn.return');
    modalRef.componentInstance.btnAccept = this.translateService.resolveMessage('document-detail.cancel-confirmation.btn.confirm');
    modalRef.result.then(() => {
      this.documentService.cancelDocument(this.documentId)
        .then(() => {
          this.toastNotificationService.success('document-detail.success.cancel-document');
          this.refreshDatas();
        })
        .catch(() => {
          this.toastNotificationService.error('document-detail.error.cancel-document');
        });
    });
  }

  /**
   * colorization of the badge according to the status of the document
   *
   * @param status: the status of the document
   */
  public formatBadge(status): string {
    switch (status) {

      case StatusFilter.CANCELED:
        return 'badge-danger';

      case StatusFilter.ALL_SIGNED:
        return 'badge-success';

      default:
        return 'badge-primary';
    }
  }

  public minSignatoriesExpected(): number {
    return this.sharedDocument.signatories.length - 1 > 0 ? this.sharedDocument.signatories.length - 1 : 1;
  }

  /**
   * Copy the code on click
   */
  public copyCode(): void {
    this.clipboardService.copy(this.sharedDocument.accessCode);
  }

  /**
   * Copy the acces url on click
   */
  public copyUrl(): void {
    this.clipboardService.copy(this.sharedDocument.accessUrl);
  }

  /**
   * Context update
   */
  private updateContext(): void {
    this.ctx.displaySharedInfo = this.sharedDocument.isOwner
      && (this.sharedDocument.status === StatusFilter.WAITING_SIGNATURES || this.sharedDocument.status === StatusFilter.TO_CLOSE);
    this.ctx.expireDateInfo = (this.sharedDocument.status === StatusFilter.WAITING_SIGNATURES
      || this.sharedDocument.status === StatusFilter.TO_CLOSE) ? 'TO_SIGN' : 'EXPIRED';
    this.ctx.isSignButton = this.sharedDocument.status === StatusFilter.TO_SIGNED && !this.sharedDocument.isOwner;
    this.ctx.closeable = this.sharedDocument.isOwner && this.sharedDocument.status !== StatusFilter.ALL_SIGNED;
    this.ctx.cancellable = this.sharedDocument.isOwner
      && (this.sharedDocument.status === StatusFilter.WAITING_SIGNATURES || this.sharedDocument.status === StatusFilter.TO_CLOSE);
    this.ctx.canceled = this.sharedDocument.status === StatusFilter.CANCELED;
  }

  /**
   * Formats the url that allows access to the shared document
   */
  private buildAccesUrl(): string {
    return `${window.location.protocol}//${window.location.host}/guest/home/${this.documentId}`;
  }
}

