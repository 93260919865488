import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ShareDocumentResolver } from '@core/resolvers/share-document.resolver';
import { AuthGuard } from '@guards/auth.guard';
import { GcuComponent } from '@pages/gcu/gcu.component';
import { GuestHomeComponent } from '@pages/guest/home/guest-home.component';
import { MyDocumentsComponent } from '@pages/my-documents/my-documents.component';
import { MenuRoutingModule } from '@pages/routing/menu-routing.module';
import { DocumentDetailComponent } from '@pages/signature/document-detail/document-detail.component';
import { PreviewBeforeSignComponent } from '@pages/signature/preview-before-sign/preview-before-sign.component';
import { ShareDocumentComponent } from '@pages/signature/share-document/share-document.component';
import { SignConfirmationComponent } from '@pages/signature/sign-confirmation/sign-confirmation.component';
import { SignatureDocumentComponent } from '@pages/signature/signature-document/signature-document.component';
import { PreviewDocumentComponent } from '@pages/signature/statement/preview-document/preview-document.component';
import { UploadComponent } from '@pages/signature/upload-pdf/upload.component';
import { ValidationCodeComponent } from '@pages/signature/validation-code/validation-code.component';
import { DocumentInfoResolver } from '@resolvers/document-info.resolver';
import { UploadResolver } from '@resolvers/upload.resolver';
import { NotificationActiveResolver } from '@resolvers/notification-active.resolver';
import { LegalNoticeComponent } from '@core/layout/components/footer/components/legal-notice/legal-notice.component';
import { CookiesComponent } from '@core/layout/components/footer/components/cookies/cookies.component';
import { ContactComponent } from '@core/layout/components/footer/components/contact/contact.component';
import { PreviewPdfResolver } from '@resolvers/preview-pdf.resolver';
import { IncompatibleBrowserComponent } from '@pages/incompatible-browser/incompatible-browser';
import { BrowserGuard } from '@guards/browser.guard';

const routes: Routes = [
  { path: '', redirectTo: 'signature/dashboard', pathMatch: 'full' },
  { path: 'login', redirectTo: 'signature/dashboard' },
  { path: 'gcu', component: GcuComponent, canActivate: [AuthGuard, BrowserGuard], data: { gcuReadOnly: false } },
  { path: 'read-gcu', component: GcuComponent, canActivate: [AuthGuard, BrowserGuard], data: { gcuReadOnly: true }},
  { path: 'my-documents', component: MyDocumentsComponent, canActivate: [AuthGuard, BrowserGuard] },
  { path: 'legal-notice', component: LegalNoticeComponent, canActivate: [BrowserGuard]},
  { path: 'cookies', component: CookiesComponent, canActivate: [BrowserGuard] },
  { path: 'contact', component: ContactComponent, canActivate: [BrowserGuard] },
  { path: 'incompatible-browser', component: IncompatibleBrowserComponent },
  {
    path: 'document-detail/:id', component: DocumentDetailComponent, canActivate: [AuthGuard, BrowserGuard],
    resolve: { sharedDocument: ShareDocumentResolver }
  },
  {
    path: 'signature',
    canActivate: [AuthGuard, BrowserGuard],
    children: [
      { path: 'upload-pdf', component: UploadComponent },
      { path: 'upload-pdf/:id', component: UploadComponent, resolve: { infoUploadDocument: UploadResolver } },
      { path: 'confirmation/:id', component: SignConfirmationComponent },
      { path: 'preview-pdf/:id', component: PreviewBeforeSignComponent },
      { path: ':id', component: SignatureDocumentComponent },
      {
        path: 'share-document/:id', component: ShareDocumentComponent,
        resolve: { sharedDocument: ShareDocumentResolver, notification: NotificationActiveResolver }
      },
      { path: 'guest/validation-code/:id', component: ValidationCodeComponent, resolve: { info: DocumentInfoResolver } },
      { path: 'guest/preview-pdf/:id', component: PreviewDocumentComponent, resolve: { base64Document: PreviewPdfResolver } },
      { path: 'guest/:id', component: SignatureDocumentComponent, data: { context: 'guest' } }
    ]
  },
  { path: 'guest/home/:id', component: GuestHomeComponent, resolve: { info: DocumentInfoResolver }, canActivate: [BrowserGuard] },
  { path: '**', redirectTo: 'signature/dashboard' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'top',
    relativeLinkResolution: 'legacy'
}),
    MenuRoutingModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
