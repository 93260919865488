export enum ErrorCodes {
  BUSI_SIGN_01 = 'BUSI-SIGN-01',
  BUSI_SIGN_02 = 'BUSI-SIGN-02',
  BUSI_SIGN_03 = 'BUSI-SIGN-03',
  NOT_PDF = 'BUSI-SIGN-04',
  INVALID_ACCES_CODE = 'BUSI-SIGN-08',
  DOCUMENT_ALREADY_SIGNED = 'BUSI-SIGN-11',
  DOCUMENT_COMPLETED = 'BUSI-SIGN-12',
  NUMBER_OF_SIGNATORIES_EXCEDEED = 'BUSI-SIGN-13',
  INVALID_CERT = 'BUSI-SIGN-14',
  CERTIFICATE_EXPIRED = 'BUSI-SIGN-15',
  CERTIFICATE_REVOKED = 'BUSI-SIGN-16',
  CERTIFICATE_NOT_YET_VALID = 'BUSI-SIGN-17',
  CANNOT_DETRMINE_CERT = 'BUSI-SIGN-18',
  CHECK_IDENTITY_FAILED = 'BUSI-SIGN-20',
  TOO_MANY_CODE_ATTEMPTS = 'BUSI-SIGN-21',
  DOCUMENT_CANCELED = 'BUSI-SIGN-22'
}
