import {Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from '@services/notification/notification.service';
import { StringUtils } from '@ingroupe/common-utils';
import { ToastNotificationService } from '@services/toast-notification/toast-notification.service';
import { ActivatedRoute } from '@angular/router';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { Subscription } from 'rxjs';
import { IngroupeTranslateService } from '@ingroupe/translate';

@AutoUnsubscribe()
@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit, OnDestroy {

  /**
   * The share form
   */
  public notificationForm: FormGroup;

  /**
   * Show or hide notification section
   */
  public showNotificationSection: boolean;

  /**
   * The last register mail
   */
  public lastRegisterMail: string;

  /**
   * change the display format of Mail element
   */
  public inputMailEditMode: boolean;

  /**
   * Direct access to form controls
   */
  controls: {
    [key: string]: AbstractControl;
  };

  // eslint-disable-next-line
  private notificationMailRegex = /^(([^<>()\[\].,;:\s@"]+(\.[^<>()\[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
  /**
   * The route data subsciption
   */
  private routeDataSubscription: Subscription;

  constructor(private formBuilder: FormBuilder,
              private notificationService: NotificationService,
              private toastNotificationService: ToastNotificationService,
              private route: ActivatedRoute, private i18nService: IngroupeTranslateService) {
  }

  ngOnInit(): void {
    this.routeDataSubscription = this.route.data.subscribe(
      data => this.getNotificationState(data.notification.email),
      () => this.toastNotificationService.error('notifications.toast.errors.generic'));

    this.notificationForm = this.formBuilder.group({
      notificationMail: [null, [Validators.required, Validators.pattern(this.notificationMailRegex)]],
    });

    this.controls = this.notificationForm.controls;
  }

  /**
   * Activate notifications
   */
  public activateNotification(): Promise<void> {
    return this.notificationService.register(this.notificationForm.get('notificationMail').value, this.i18nService.getCurrentLang())
      .then(() => {
        this.lastRegisterMail = this.notificationForm.get('notificationMail').value;
        this.toastNotificationService.success('notifications.toast.success.activate');
        this.inputMailEditMode = !this.inputMailEditMode;
      })
      .catch(() => {
        this.toastNotificationService.error('notifications.toast.errors.generic');
      });
  }

  /**
   * Deactivate notifications
   */
  public deactivateNotifications(): Promise<void> {
    return this.notificationService.unregister()
      .then(() => {
        this.toastNotificationService.success('notifications.toast.success.deactivate');
        this.controls.notificationMail.reset();
        this.notificationForm.reset();
        this.lastRegisterMail = undefined;
        this.inputMailEditMode = true;
      })
      .catch(() => {
        this.toastNotificationService.error('notifications.toast.errors.generic');
      });
  }

  /**
   * Update notification
   *
   * @param event: input event
   *
   */
  public updateNotificationState(event: Event): void {
    if ((event.target as HTMLInputElement).checked) {
      this.showNotificationSection = true;
    } else {
      this.notificationForm.setValue({ notificationMail: null });
      this.showNotificationSection = false;
      this.deactivateNotifications();
    }
  }

  /**
   * check if the email has changed
   */
  public isSameMail(): boolean {
    return StringUtils.equalsIgnoreCase(this.notificationForm.get('notificationMail').value, this.lastRegisterMail);
  }

  /**
   * Activate the modification mode for the Mail
   */
  public updateMail(): void {
    this.inputMailEditMode = !this.inputMailEditMode;
    this.notificationForm.reset();
  }

  /**
   * get state of notification
   */
  private getNotificationState(email: string): void {
    this.lastRegisterMail = email;
    this.showNotificationSection = StringUtils.isNotBlank(email);
    this.inputMailEditMode = StringUtils.isBlank(email);
    this.notificationForm.patchValue({
      notificationMail: email
    });
  }

  /**
   * On destroy component listener
   */
  public ngOnDestroy(): void {
    // DON'T REMOVE THIS METHOD ! AutoUnsubscribe use it !
  }
}
