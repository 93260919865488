import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Gcu } from '@app/shared/models/gcu/gcu.model';
import { CoreModule } from '@core/core.module';
import { environment } from '@env/environment.prod';
import { ArrayUtils, StringUtils } from '@ingroupe/common-utils';
import { map, tap } from 'rxjs/operators';
import { Md5 } from 'ts-md5/dist/md5';
import { ConfigurationService } from '@services/configuration/configuration.service';

/**
 * Service to manage gcu's
 */
@Injectable({
  providedIn: CoreModule
})
export class GcuService {

  /**
   * The GCU event emmitter
   */
  gcuState: EventEmitter<boolean>;

  /**
   * The gcu template hashes
   */
  private gcuTplHash: string;
  /**
   * Indicate if gcu has been already checked
   */
  private gcuAccepted: boolean;
  /**
   * Available langs
   */
  private availableLangs: Array<string>;

  /**
   * Constructor
   *
   * @param http : The http client
   * @param configService; the configuration service
   */
  public constructor(private http: HttpClient, private configService: ConfigurationService) {
    this.gcuTplHash = '';
    this.gcuState = new EventEmitter<boolean>();

  }

  /**
   * Initialize gcu context
   */
  public initialize(availableLangs: Array<string>): Promise<void> {
    this.availableLangs = availableLangs;
    return new Promise(async (resolve, reject) => {
      if (ArrayUtils.isNotEmpty(availableLangs)) {
        const gcuPromises: Array<Promise<Gcu>> = new Array<Promise<Gcu>>();
        availableLangs.forEach((availableLang: string) => {
          gcuPromises.push(this.getGcuContent(availableLang));
        });

        // build gcu templates hash
        const gcus: Array<Gcu> = await Promise.all(gcuPromises);
        const sortedByLangGcus: Array<Gcu> = gcus.sort((a: Gcu, b: Gcu) => a.lang.localeCompare(b.lang));
        sortedByLangGcus.forEach((gcu: Gcu) => this.gcuTplHash += (Md5.hashStr(gcu.content) as string));

        this.gcuAccepted = await this.getGcuState();
      }

      resolve();
    });
  }

  /**
   * Get the gcu template content for given lang
   *
   * @param lang : the current user's selected lang
   * @returns Promise of template content
   */
  public getGcuContent(lang: string): Promise<Gcu> {
    if (!ArrayUtils.contains(this.availableLangs, lang)) {
      return Promise.reject(`Gcu for lang '${lang}' not available`);
    }

    const cguTplPath: string = StringUtils.format(environment.gcuTemplatePath, lang);
    return this.http.get<string>(cguTplPath, { responseType: 'text' as 'json' })
      .pipe(map((content: string) => ({
          lang,
          content
        }))).toPromise();
  }

  /**
   * Indicate if the user has accepted gcu
   */
  public isAccepted(): boolean {
    this.gcuState.emit(this.gcuAccepted);
    return this.gcuAccepted;
  }

  /**
   * Accept gcu for the current user
   *
   * @returns Promise of any
   */
  public accept(): Promise<any> {
    let gcuStateUrl: string = this.configService.getEndPoint('api.put.gcuState');
    gcuStateUrl = StringUtils.format(gcuStateUrl, this.gcuTplHash);
    return this.http.put(gcuStateUrl, {}).pipe(tap(() => this.gcuAccepted = true)).toPromise();
  }

  /**
   * Get the gcu state for the current user
   *
   * @returns Promise of boolean indicate if user has accepted gcu
   */
  private getGcuState(): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      try {
        let gcuStateUrl: string = this.configService.getEndPoint('api.get.gcuState');
        gcuStateUrl = StringUtils.format(gcuStateUrl, this.gcuTplHash);
        await this.http.get(gcuStateUrl).toPromise();
        resolve(true);
      } catch (e) {
        resolve(false);
      }
    });
  }
}
