import { Injectable } from '@angular/core';
import { CoreModule } from '../core.module';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { UploadPdfService } from '@services/document/upload-pdf.service';

@Injectable({ providedIn: CoreModule })
export class PreviewPdfResolver implements Resolve<ArrayBuffer> {
  constructor(
    private uploadPdfService: UploadPdfService,
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<ArrayBuffer> {
    const documentId: string = route.paramMap.get('id');
    return this.uploadPdfService.documentUnsignedSignatory(documentId)
      .catch(() => {
      throw new Error('Unable to get document pdf...');
    });
  }
}
